
import { http } from "./utils/http";

const loginApi = (user: string, password: string) => {
    return http.post('/login/', {
        username: user,
        password : password
      })
}

const isTokenValid = () => {
    return http.get('/session/');
}

export default {isTokenValid, loginApi}

