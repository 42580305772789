import axios from "axios";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import _ from 'lodash';
import { Buscador } from "../componentes/Buscador";

interface RouteParams {
  id: string
}

interface PropBuscadorAvanzado extends RouteComponentProps<RouteParams>{

}

type StateBuscadorAvanzado = {
  isMobile: boolean;
  lateralVisible: boolean;
  sistemas: any[] | [];
  subsistemas: any[] | [];
  tarjetas: any[] | [];
  equipos: any[] | [];
  filtros: any;

  filtrarSistemas: boolean;
  filtrarSubsistemas: boolean;
  filtrarTarjetas: boolean;
  filtrarEquipos: boolean;

  plataformasSeleccionadas: number[] | [];
  sistemasSeleccionadas: number[] | [];
  subsistemasSeleccionadas: number[] | [];
}

export class BuscadorAvanzado extends Component<PropBuscadorAvanzado, StateBuscadorAvanzado> { 

    query: string = "";

    constructor(props: PropBuscadorAvanzado) {
      super(props);
      let filtrosBusqueda = this.props.location.search;
      let plataformas: number[] | [] = [];
      let sistemas: number[] | [] = [];
      let subsistemas: number[] | [] = [];
      let filtrarSistemas = true;
      let filtrarSubsistemas = true;
      let filtrarTarjetas = true;
      let filtrarEquipos = true;
      if (filtrosBusqueda !== "") {
        this.query = this.getParametroURLQuery("q") as string;
        if (this.getParametroURLQuery("plataformas")) {
          plataformas = JSON.parse(decodeURI(this.getParametroURLQuery("plataformas") as string));
        }
        if (this.getParametroURLQuery("sistemas")) {
          sistemas = JSON.parse(decodeURI(this.getParametroURLQuery("sistemas") as string));
        }
        if (this.getParametroURLQuery("subsistemas")) {
          subsistemas = JSON.parse(decodeURI(this.getParametroURLQuery("subsistemas") as string));
        }
        if (this.hasParametroURLQuery("filtrarSistemas")) {
          filtrarSistemas = this.getParametroURLQuery("filtrarSistemas") == "true";
        }
        if (this.hasParametroURLQuery("filtrarSubsistemas")) {
          filtrarSubsistemas = this.getParametroURLQuery("filtrarSubsistemas") == "true";
        }
        if (this.hasParametroURLQuery("filtrarTarjetas")) {
          filtrarTarjetas = this.getParametroURLQuery("filtrarTarjetas") == "true";
        }
        if (this.hasParametroURLQuery("filtrarEquipos")) {
          filtrarEquipos = this.getParametroURLQuery("filtrarEquipos") == "true";
        }
      }
      this.state = {
        isMobile: window.innerWidth < 860,
        lateralVisible: window.innerWidth < 860 ? false : true,
        sistemas: [],
        subsistemas: [],
        tarjetas: [],
        equipos: [],
        filtros: {} as any,
  
        filtrarSistemas: filtrarSistemas,
        filtrarSubsistemas: filtrarSubsistemas,
        filtrarTarjetas: filtrarTarjetas,
        filtrarEquipos: filtrarEquipos,
        
        plataformasSeleccionadas: plataformas ? plataformas: [],
        sistemasSeleccionadas: sistemas ? sistemas: [],
        subsistemasSeleccionadas: subsistemas ? subsistemas: [],
      }
    }

    

    handleWindowResize = () => {
      this.setState(
        { isMobile: window.innerWidth < 860,
          lateralVisible: window.innerWidth < 860 ? false : true
        }
      );
    }


    componentDidMount() {    
      this.buscar();
      window.addEventListener('resize', this.handleWindowResize);
    }

    componentDidUpdate(prevProps: PropBuscadorAvanzado, prevState: StateBuscadorAvanzado) {
      if (prevProps.location.pathname != this.props.location.pathname || prevProps.location.search != this.props.location.search) {
        let filtrosBusqueda = this.props.location.search;
        let plataformas: number[] | [] = [];
        let sistemas: number[] | [] = [];
        let subsistemas: number[] | [] = [];
        let filtrarSistemas = this.state.filtrarSistemas;
        let filtrarSubsistemas = this.state.filtrarSubsistemas;
        let filtrarTarjetas = this.state.filtrarTarjetas;
        let filtrarEquipos = this.state.filtrarEquipos;
        if (filtrosBusqueda !== "") {
          let queryAnterior = _.clone(this.query)
          this.query = this.getParametroURLQuery("q") as string;
          if (this.getParametroURLQuery("plataformas")) {
            plataformas = JSON.parse(decodeURI(this.getParametroURLQuery("plataformas") as string));
          }
          if (this.getParametroURLQuery("sistemas")) {
            sistemas = JSON.parse(decodeURI(this.getParametroURLQuery("sistemas") as string));
          }
          if (this.getParametroURLQuery("subsistemas")) {
            subsistemas = JSON.parse(decodeURI(this.getParametroURLQuery("subsistemas") as string));
          }    
          if (this.hasParametroURLQuery("filtrarSistemas")) {
            filtrarSistemas = this.getParametroURLQuery("filtrarSistemas") == "true";
          }
          if (this.hasParametroURLQuery("filtrarSubsistemas")) {
            filtrarSubsistemas = this.getParametroURLQuery("filtrarSubsistemas") == "true";
          }
          if (this.hasParametroURLQuery("filtrarTarjetas")) {
            filtrarTarjetas = this.getParametroURLQuery("filtrarTarjetas") == "true";
          }
          if (this.hasParametroURLQuery("filtrarEquipos")) {
            filtrarEquipos = this.getParametroURLQuery("filtrarEquipos") == "true";
          }
          if (queryAnterior != this.query 
          || !_.isEqual(plataformas, prevState.plataformasSeleccionadas)
           || !_.isEqual(sistemas, prevState.sistemasSeleccionadas)
           || !_.isEqual(subsistemas, prevState.subsistemasSeleccionadas)
          ) { 
            this.setState({
              plataformasSeleccionadas: plataformas ? plataformas: [],
              sistemasSeleccionadas: sistemas ? sistemas: [],
              subsistemasSeleccionadas: subsistemas ? subsistemas: [],

              filtrarSistemas: filtrarSistemas,
              filtrarSubsistemas: filtrarSubsistemas,
              filtrarTarjetas: filtrarTarjetas,
              filtrarEquipos: filtrarEquipos,
            }, () => this.buscar())
          }          
        }
      }
    }

    getParametroURLQuery = (variable: string) => {
      var query = this.props.location.search.substring(1);
      var vars = query.split("&");
      for (var i=0;i<vars.length;i++) {
              var pair = vars[i].split("=");                   
              if(pair[0] == variable){return pair[1];}
      }
      return(false);
    }

    hasParametroURLQuery = (variable: string) => {
      var query = this.props.location.search.substring(1);
      var vars = query.split("&");
      for (var i=0;i<vars.length;i++) {
              var pair = vars[i].split("=");                   
              if(pair[0] == variable){return true}
      }
      return(false);
    }

    tieneTodosFiltrosSeleccionados = () => {
      if (this.state.filtrarSistemas && this.state.filtrarSubsistemas && this.state.filtrarTarjetas && this.state.filtrarEquipos) {
        return true;
      } else return false;
    }

    tieneSoloFiltroSistemas = () => {
      if (this.state.filtrarSistemas && !this.state.filtrarSubsistemas && !this.state.filtrarTarjetas && !this.state.filtrarEquipos) {
        return true;
      } else return false;
    }

    tieneSoloFiltroSubsistemas = () => {
      if (!this.state.filtrarSistemas && this.state.filtrarSubsistemas && !this.state.filtrarTarjetas && !this.state.filtrarEquipos) {
        return true;
      } else return false;
    }

    tieneSoloFiltroTarjetas = () => {
      if (!this.state.filtrarSistemas && !this.state.filtrarSubsistemas && this.state.filtrarTarjetas && !this.state.filtrarEquipos) {
        return true;
      } else return false;
    }

    tieneSoloFiltroEquipos = () => {
      if (!this.state.filtrarSistemas && !this.state.filtrarSubsistemas && !this.state.filtrarTarjetas && this.state.filtrarEquipos) {
        return true;
      } else return false;
    }

    buscar() {
      let instancia = this;
      if (!this.hayFiltrosSeleccionados()) {
        axios.all([axios.get('/search-tool/?field=sistema&search=' + this.query),
        axios.get('/search-tool/?field=subsistema&search=' + this.query),
        axios.get('/search-tool/?field=tarjeta&search=' + this.query),
        axios.get('/search-tool/?field=equipo&search=' + this.query),
        axios.get('/available-filters/?search=' + this.query)])
        .then(axios.spread(function (sistemas, subsistemas, tarjetas, equipos, filtros) {
          let sistemasBuscadas = sistemas.data;
          let subsistemasBuscadas = subsistemas.data;
          let tarjetasBuscadas = tarjetas.data;
          let equiposBuscados = equipos.data;
          let filtrosBuscados = filtros.data;
          instancia.setState({
              sistemas: sistemasBuscadas,
              subsistemas: subsistemasBuscadas,
              tarjetas: tarjetasBuscadas,
              equipos: equiposBuscados,
              filtros: filtrosBuscados
          }, () => instancia.cambiarURL())          
        }));
      } else {
        const getPostData = (field: string) => {
          return { 
            "field": field,
            "search": this.query,
            "plataformas": this.state.plataformasSeleccionadas,
            "sistemas": this.state.sistemasSeleccionadas,
            "subsistemas": this.state.subsistemasSeleccionadas
          }         
        }
        axios.all([axios.post('/search-tool-filters/', getPostData("sistema")),
        axios.post('/search-tool-filters/', getPostData("subsistema")),
        axios.post('/search-tool-filters/', getPostData("tarjeta")),
        axios.post('/search-tool-filters/', getPostData("equipo")),
        axios.get('/available-filters/?search=' + this.query)])
        .then(axios.spread(function (sistemas, subsistemas, tarjetas, equipos, filtros) {
          let sistemasBuscadas = sistemas.data;
          let subsistemasBuscadas = subsistemas.data;
          let tarjetasBuscadas = tarjetas.data;
          let equiposBuscados = equipos.data;
          let filtrosBuscados = filtros.data;
          instancia.setState({
              sistemas: sistemasBuscadas,
              subsistemas: subsistemasBuscadas,
              tarjetas: tarjetasBuscadas,
              equipos: equiposBuscados,
              filtros: filtrosBuscados
          }, () => instancia.cambiarURL())          
        }));
      } 
    }

    hayFiltrosSeleccionados () {
      return (this.state.plataformasSeleccionadas.length > 0 || this.state.sistemasSeleccionadas.length > 0 || this.state.subsistemasSeleccionadas.length > 0)
    }

    cambiarURL = () => {
      this.props.history.replace("/portada/buscador-avanzado?q=" + this.query
      + (this.state.plataformasSeleccionadas.length > 0 ? "&plataformas=" + encodeURI(JSON.stringify(this.state.plataformasSeleccionadas)) : "")
      + (this.state.sistemasSeleccionadas.length > 0 ? "&sistemas=" + encodeURI(JSON.stringify(this.state.sistemasSeleccionadas))  : "")
      + (this.state.subsistemasSeleccionadas.length > 0 ? "&subsistemas=" + encodeURI(JSON.stringify(this.state.subsistemasSeleccionadas))  : "")
      + "&filtrarSistemas=" + this.state.filtrarSistemas
      + "&filtrarSubsistemas=" + this.state.filtrarSubsistemas
      + "&filtrarTarjetas=" + this.state.filtrarTarjetas
      + "&filtrarEquipos=" + this.state.filtrarEquipos
      )
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowResize);
    }

    onVolver = () => {
      this.props.history.goBack();
    }

    onVolverPortada = () => {
      this.props.history.push("/portada")
    }

    onVolverModelo = () => {
      this.props.history.push("/portada/modelo/2?sistema=1")
    }


    toggleLateral = () => {
      this.setState({
        lateralVisible: !this.state.lateralVisible
      })
    }

    onChangeFiltrarSistemas = () => {
      if (this.tieneTodosFiltrosSeleccionados()) {
        this.setState({
          filtrarSistemas: true,
          filtrarSubsistemas: false,
          filtrarTarjetas: false,
          filtrarEquipos: false,
          lateralVisible: false
        }, () => this.buscar())
      } else if (this.tieneSoloFiltroSistemas()) {
        this.setState({
          filtrarSistemas: true,
          filtrarSubsistemas: true,
          filtrarTarjetas: true,
          filtrarEquipos: true,
          lateralVisible: true
        })
      } else {
        this.setState({
          filtrarSistemas: !this.state.filtrarSistemas,
          lateralVisible: false
        }, () => this.buscar())
      }     
    }

    onChangeFiltrarSubSistemas = () => {
      if (this.tieneTodosFiltrosSeleccionados()) {
        this.setState({
          filtrarSistemas: false,
          filtrarSubsistemas: true,
          filtrarTarjetas: false,
          filtrarEquipos: false,
          lateralVisible: false
        }, () => this.buscar())
      } else if (this.tieneSoloFiltroSubsistemas()) {
        this.setState({
          filtrarSistemas: true,
          filtrarSubsistemas: true,
          filtrarTarjetas: true,
          filtrarEquipos: true,
          lateralVisible: true
        })
      } else {
        this.setState({
          filtrarSubsistemas: !this.state.filtrarSubsistemas,
          lateralVisible: false
        }, () => this.buscar())
      }
    }

    onChangeFiltrarTarjetas = () => {
      if (this.tieneTodosFiltrosSeleccionados()) {
        this.setState({
          filtrarSistemas: false,
          filtrarSubsistemas: false,
          filtrarTarjetas: true,
          filtrarEquipos: false,
          lateralVisible: false
        }, () => this.buscar())
      } else if (this.tieneSoloFiltroTarjetas()) {
        this.setState({
          filtrarSistemas: true,
          filtrarSubsistemas: true,
          filtrarTarjetas: true,
          filtrarEquipos: true,
          lateralVisible: true
        })
      } else {
        this.setState({
          filtrarTarjetas: !this.state.filtrarTarjetas,
          lateralVisible: false
        }, () => this.buscar())
      }      
    }

    onChangeFiltrarEquipos = () => {
      if (this.tieneTodosFiltrosSeleccionados()) {
        this.setState({
          filtrarSistemas: false,
          filtrarSubsistemas: false,
          filtrarTarjetas: false,
          filtrarEquipos: true,
          lateralVisible: false
        }, () => this.buscar())
      } else if (this.tieneSoloFiltroEquipos()) {
        this.setState({
          filtrarSistemas: true,
          filtrarSubsistemas: true,
          filtrarTarjetas: true,
          filtrarEquipos: true,
          lateralVisible: true
        })
      } else {
        this.setState({
          filtrarEquipos: !this.state.filtrarEquipos,
          lateralVisible: false
        }, () => this.buscar())
      }     
    }

    onChangeFiltroPlataforma = (idPlataforma: number) => {
      var plataformasSeleccionadas: number[] = _.cloneDeep(this.state.plataformasSeleccionadas);
      if (_.indexOf(plataformasSeleccionadas, idPlataforma) !== -1) {
        _.pull(plataformasSeleccionadas, idPlataforma);
      } else {
        plataformasSeleccionadas.push(idPlataforma)
      }
      this.setState({
        plataformasSeleccionadas: plataformasSeleccionadas,
        lateralVisible: false
      }, () => this.buscar())
    }

    onChangeFiltroSistema = (idSistema: number) => {
      var sistemasSeleccionadas: number[] = _.cloneDeep(this.state.sistemasSeleccionadas);
      if (_.indexOf(sistemasSeleccionadas, idSistema) !== -1) {
        _.pull(sistemasSeleccionadas, idSistema);
      } else {
        sistemasSeleccionadas.push(idSistema)
      }
      this.setState({
        sistemasSeleccionadas: sistemasSeleccionadas,
        lateralVisible: false
      }, () => this.buscar())
    }


    onChangeFiltroSubsistema = (idSubsistema: number) => {
      var subsistemasSeleccionadas: number[] = _.cloneDeep(this.state.subsistemasSeleccionadas);
      if (_.indexOf(subsistemasSeleccionadas, idSubsistema) !== -1) {
        _.pull(subsistemasSeleccionadas, idSubsistema);
      } else {
        subsistemasSeleccionadas.push(idSubsistema)
      }
      this.setState({
        subsistemasSeleccionadas: subsistemasSeleccionadas,
        lateralVisible: false
      }, () => this.buscar())
    }

    onDocumentacion(plataforma: any, sistema: any, subsistema: any, equipo: any) {
      this.props.history.push("/portada/modelo/" + plataforma + (sistema ? "?sistema=" + sistema : "") + (subsistema ? "&subsistema=" + subsistema : "")  + (equipo ? "&equipo=" + equipo : ""), true)
    }

    onComparador(tarjeta: any, nombreTarjeta: any, equipo: any) {
      this.props.history.push("/portada/comparador?tarjeta=" + tarjeta + "&nombre=" + encodeURI(nombreTarjeta) + (equipo ? '&equipos=["' + encodeURI(equipo) + '"]' : ""), true)
    }

    render() {

        return (
            <div className="libro">
              <div className="topnav">
                <div className="topnav__nav"> 
                  <button className="btn-volver" onClick={this.onVolver}></button>
                  <div className="breadcrumb">
                    <a className="breadcrumb__portada" onClick={this.onVolverPortada}>Portada</a>
                    /
                    <a onClick={this.onVolverModelo}>Modelo</a>
                    /
                    <a className="breadcrumb__activo">Buscador avanzado</a>
                  </div>
                </div>
                <div className="topnav__buscador">
                  <Buscador {...this.props}/>
                </div>
                <div className="topnav-logo-eroski"></div>
              </div>

              <div className="sidenav" style={{display: !this.state.isMobile || this.state.lateralVisible ? 'block' : 'none'}}>
                <button className="btn-cerrar" onClick={this.toggleLateral}></button>
                <h4>Filtrar resultados</h4>
                <div><input type="checkbox" checked={this.state.filtrarSistemas && !this.tieneTodosFiltrosSeleccionados()} onChange={() => this.onChangeFiltrarSistemas()}/> Sistema</div>
                <div><input type="checkbox" checked={this.state.filtrarSubsistemas && !this.tieneTodosFiltrosSeleccionados()} onChange={() => this.onChangeFiltrarSubSistemas()}/> Subsistema</div>
                <div><input type="checkbox" checked={this.state.filtrarTarjetas && !this.tieneTodosFiltrosSeleccionados()} onChange={() => this.onChangeFiltrarTarjetas()}/> Tarjeta</div>
                <div><input type="checkbox" checked={this.state.filtrarEquipos && !this.tieneTodosFiltrosSeleccionados()} onChange={() => this.onChangeFiltrarEquipos()}/> Equipo</div>

                {this.state.filtros && this.state.filtros.plataformas && (
                  <div style={{marginTop: '1rem'}}>
                  <label>Plataformas</label>
                  {this.state.filtros.plataformas.map((plataforma: any) =>
                    plataforma.nombre && <div><input type="checkbox" checked={_.indexOf(this.state.plataformasSeleccionadas, plataforma.id) !== -1} onChange={() => this.onChangeFiltroPlataforma(plataforma.id)}/> {plataforma.nombre}</div>
                  )}
                  </div>
                )}
                {this.state.filtros && this.state.filtros.sistemas && (
                  <div style={{marginTop: '1rem'}}>
                  <label>Sistemas</label>
                  {this.state.filtros.sistemas.map((sistema: any) =>
                    sistema.nombre && <div><input type="checkbox" checked={_.indexOf(this.state.sistemasSeleccionadas, sistema.id) !== -1} onChange={() => this.onChangeFiltroSistema(sistema.id)}/> {sistema.nombre}</div>
                  )}
                  </div>
                )}
                {this.state.filtros && this.state.filtros.subsistemas && (
                  <div style={{marginTop: '1rem'}}>
                  <label>Subsistemas</label>
                  {this.state.filtros.subsistemas.map((subsistema: any) =>
                    subsistema.nombre && <div><input type="checkbox" checked={_.indexOf(this.state.subsistemasSeleccionadas, subsistema.id) !== -1} onChange={() => this.onChangeFiltroSubsistema(subsistema.id)}/> {subsistema.nombre}</div>
                  )}
                  </div>
                )}
                
              </div>

              <div className="main main__buscador" style={{display: !this.state.isMobile || !this.state.lateralVisible ? 'block' : 'none'}}>
                <button className="btn-menu" onClick={this.toggleLateral}></button>
                  <h2>Resultados para "{this.query}"</h2>
                  <div className="main__tarjetas">
                    {this.state.filtrarSistemas && this.state.sistemas.length > 0 && this.state.sistemas.map((sistema:any) => (
                        <div className="main__tarjeta">
                          <div className="main__buscador__tarjeta">
                            <p className="main__buscador__tarjeta__ruta">
                              {sistema.plataforma.nombre}
                            </p>
                            <p className="main__buscador__tarjeta__nombre">{sistema.nombre}</p>
                            <div className="main__buscador__tarjeta__footer">
                              <div className="tipo sistema"></div>
                              <div className="botones">
                                <button className="boton-documentacion"  onClick={() => this.onDocumentacion(sistema.plataforma.id, sistema.id, null, null)}>Ver</button>
                              </div> 
                            </div>
                          </div> 
                        </div>                      
                    ))}
                    {this.state.filtrarSubsistemas && this.state.subsistemas.length > 0 && this.state.subsistemas.map((subsistema:any) => (
                        <div className="main__tarjeta">
                          <div className="main__buscador__tarjeta">
                            <p className="main__buscador__tarjeta__ruta">
                            {subsistema.plataforma.nombre} / {subsistema.sistema_desc}
                            </p>
                            <p className="main__buscador__tarjeta__nombre">{subsistema.nombre}</p>
                            <div className="main__buscador__tarjeta__footer">
                              <div className="tipo subsistema"></div>
                              <div className="botones">
                                <button className="boton-documentacion" onClick={() => this.onDocumentacion(subsistema.plataforma.id, subsistema.sistema, subsistema.id, null)}>Ver</button>
                              </div> 
                            </div>
                          </div> 
                        </div>                     
                    ))}
                    {this.state.filtrarTarjetas && this.state.tarjetas.length > 0 && this.state.tarjetas.map((tarjeta:any) => (
                        tarjeta.plataformas.map((plataformaTarjeta: any) => (
                        <div className="main__tarjeta">
                          <div className="main__buscador__tarjeta">
                            <p className="main__buscador__tarjeta__ruta">
                              {plataformaTarjeta.nombre} / {tarjeta.sistema_desc} / {tarjeta.subsistema_desc}
                            </p>
                            <p className="main__buscador__tarjeta__nombre">{tarjeta.nombre}</p>
                            <div className="main__buscador__tarjeta__footer">
                              <div className="tipo tarjeta"></div>
                              <div className="botones">
                                <button onClick={() => this.onComparador(tarjeta.id, tarjeta.nombre, null)}>Comparar</button>
                                <button className="boton-documentacion" onClick={() => this.onDocumentacion(plataformaTarjeta.id, tarjeta.sistema, tarjeta.subsistema, null)}>Ver</button>
                              </div> 
                            </div>
                          </div> 
                        </div>
                        ))                        
                    ))}
                    {this.state.filtrarEquipos && this.state.equipos.length > 0 && this.state.equipos.map((equipo:any) => (     
                        <div className="main__tarjeta">
                          <div className="main__buscador__tarjeta">
                            <p className="main__buscador__tarjeta__ruta">
                              {equipo.plataforma_desc} / {equipo.sistema_desc} / {equipo.subsistema_desc}
                              <br></br>
                              {equipo.tarjeta_desc}
                            </p>
                            <p className="main__buscador__tarjeta__nombre">{equipo.nombre}</p>
                            <div className="main__buscador__tarjeta__footer">
                              <div className="tipo equipo"></div>
                              <div className="botones">
                                <button onClick={() => this.onComparador(equipo.tarjeta, equipo.tarjeta_desc, equipo.nombre)}>Comparar</button>
                                <button className="boton-documentacion" onClick={() => this.onDocumentacion(equipo.plataforma, equipo.sistema, equipo.subsistema, equipo.id)}>Ver</button>
                              </div> 
                            </div>
                          </div> 
                        </div>                       
                    ))}
                  </div>
              </div> {/* fin .main */}
            </div>

          );
    }

   
}