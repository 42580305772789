import { http } from "./utils/http";
import {onBoarding} from "../context/OnBoardingContext"


const putOnboardingCompletado = ( data: onBoarding) => {
    let user = window.sessionStorage.getItem('username');
    return http.put('/update-onboarding/'+ user, data);
    // return http.put('/update-onboarding/'+ user, { 'visit_portada' : true});
}

export default putOnboardingCompletado;

