import axios, { AxiosResponse } from "axios";
import _ from "lodash";
import { Component } from "react";
import { getFechaFormateadaSinHora } from "../utils/FuncionesFechas";

interface PropHistoricoEquipo  {   
    equipo: any;
    onCerrarModal: () => void;
}

type StateHistoricoEquipo = {
    idEquipoDesplegado: number | null;
    equipos: any[] | [];
}

export class ModalHistoricoEquipo extends Component<PropHistoricoEquipo, StateHistoricoEquipo> {


    state={
        idEquipoDesplegado: null,
        equipos: []
    }

    componentDidMount() {      
      this.recuperarHistoricoEquipo();
    }

    onCerrarModal = () => {
      this.props.onCerrarModal()
    }

    recuperarHistoricoEquipo() {
      axios.get('/lista-equipo-hist/', {params: {equipo_id: this.props.equipo.id}}).then((response: AxiosResponse) => {
        this.setState({
          equipos: response.data,
          idEquipoDesplegado: response.data.length > 0 ? response.data[0].id : null
        })
      })
    }

    onDesplegarEquipo = (id: number) => {
       if (this.state.idEquipoDesplegado && this.state.idEquipoDesplegado == id) {
         this.setState({
          idEquipoDesplegado: null
         })
       } else {
        this.setState({
          idEquipoDesplegado: id
         })
       }
    }

    estaDesplegado(equipo: any) {
      if (this.state.idEquipoDesplegado && this.state.idEquipoDesplegado == equipo.id) {
          return true;
      } else {
          return false;
      }
  }

  tieneDatosCompletosEquipo(equipo: any, conNombre: boolean) {
    if (conNombre) {
      if (equipo && (equipo.nombre || equipo.descripcion || equipo.fichero_pdf || equipo.url || equipo.fotos.length > 0)) {
        return true;
      } else return false;
    } else {
      if (equipo && (equipo.descripcion || equipo.fichero_pdf || equipo.url || equipo.fotos.length > 0)) {
        return true;
      } else return false;
    }
  }

    render () {
        let equipo = this.props.equipo;

        return(
          <div className="modal-historico" style={{display: 'block'}}>
            <div className="historial__equipo">
                    <div className={"historial__equipo__titulo " + (equipo.frio ? "frio " : "")}>
                      <span>Historia de: {equipo.latest_equipo.nombre}</span>
                      <div className="cerrar" onClick={this.onCerrarModal}></div>
                    </div>
                    <div className="historial__equipo__versiones" key={equipo.id}>
                    {this.state.equipos && this.state.equipos.map((equipo: any) => 
                      this.tieneDatosCompletosEquipo(equipo, true) && (
                        <>                       
                        <div onClick={() => this.tieneDatosCompletosEquipo(equipo, false) && this.onDesplegarEquipo(equipo.id)} className={"main__tarjeta__equipo " + (this.estaDesplegado(equipo) ? "equipo-activo " : "")}>
                          <span>Versión actual: {equipo.update_date ? getFechaFormateadaSinHora(new Date(equipo.update_date)) : equipo.nombre} </span>
                          {this.tieneDatosCompletosEquipo(equipo, false) && (
                            <span className="flecha-acordeon"></span>
                          )}
                        </div>                        
                        <div className="main__tarjeta__equipo__contenido">
                          {equipo.descripcion && (
                            <div className="descripcion-equipo" dangerouslySetInnerHTML={{__html: equipo.descripcion}}></div>
                          )}
                          {equipo.fichero_pdf && (
                            <div className="archivo-adjunto">
                              <a href={equipo.fichero_pdf} target="_blank">{equipo.titulo_fichero ? equipo.titulo_fichero : "Documento adjunto"}</a>
                            </div>
                          )}
                          {equipo.url && (
                            <div className="link-externo">
                              <a href={equipo.url} target="_blank">Normativa</a>
                            </div>
                          )}                          
                          {equipo.fotos && equipo.fotos.map((foto: any) => 
                            <img src={foto.foto} />
                          )}
                          {equipo.update_date && (
                            <span className="ult-act">
                              Últ. actualización: 
                              <a>{getFechaFormateadaSinHora(new Date(equipo.update_date))}</a>
                            </span>
                          )}
                        </div>
                        </>
                      )                   
                      
                    )}                     
                    </div>                   
            </div>
            </div>
        )
    }
 }
