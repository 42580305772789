import { Component } from "react";
import axios from "axios";
import { RouteComponentProps } from "react-router";
import './Buscador.css';
import notFound from "../img/not-found.png";
import _ from "lodash";
import React from "react";

interface PropsBuscador extends RouteComponentProps {
    placeholder?: string;
}

export class Buscador extends Component<PropsBuscador> {
    state = {
        query: "",
        plataformas: [],
        sistemas: [],
        subsistemas: [],
        tarjetas: [],
        equipos: [],
        buscando: false,
        cursor: null
      };
      
      refListaResultados = React.createRef<HTMLOListElement>();

    handleBorrarTextoBuscador = () => {
        this.setState({
            query: "",
            plataformas: [],
            sistemas: [],
            subsistemas: [],
            tarjetas: [],
            equipos: [],
            buscando: false,
             cursor: null

        });
    }

    handleInputChange = (event:any) => {        
        const query = event.target.value;
        
        if (query.length > 0) {
            this.setState({
                query : query,
                plataformas : [],
                sistemas: [],
                subsistemas: [],
                tarjetas: [],
                equipos: [],
                buscando: true,
                cursor: null

            }, () => this.buscar());
        } else if (query.length === 0) {
            this.setState({
                query : query,
                plataformas : [],
                sistemas: [],
                subsistemas: [],
                tarjetas: [],
                equipos: [],
                buscando: false,
                cursor: null

            });
        } else {
            this.setState({
                query : query
            });
        }
    }

    buscar() {
        let instancia = this;
        axios.all([axios.get('/search-tool/?field=plataforma&search=' + this.state.query),
                   axios.get('/search-tool/?field=sistema&search=' + this.state.query),
                   axios.get('/search-tool/?field=subsistema&search=' + this.state.query),
                   axios.get('/search-tool/?field=tarjeta&search=' + this.state.query),
                   axios.get('/search-tool/?field=equipo&search=' + this.state.query)])
                   .then(axios.spread(function (plataformas, sistemas, subsistemas, tarjetas, equipos) {
            let plataformasBuscadas = plataformas.data;
            let sistemasBuscadas = sistemas.data;
            let subsistemasBuscadas = subsistemas.data;
            let tarjetasBuscadas = tarjetas.data;
            let equiposBuscados = equipos.data;
            instancia.setState({
                plataformas: plataformasBuscadas,
                sistemas: sistemasBuscadas,
                subsistemas: subsistemasBuscadas,
                tarjetas: tarjetasBuscadas,
                equipos: equiposBuscados,
                buscando: false
            })
            
        }));
    }

    mostrarMasResultados() {
        this.props.history.push("/portada/buscador-avanzado?q=" + this.state.query)
    }

    seleccionarResultado(plataforma: any, sistema: any, subsistema: any) {
        this.setState({
            plataformas: [],
            sistemas: [],
            subsistemas: [],
            tarjetas: [],
            equipos: [],
            buscando: true,
            cursor:null
        }, () =>  this.props.history.push("/portada/modelo/" + plataforma.id + (sistema ? "?sistema=" + sistema : "") + (subsistema ? "&subsistema=" + subsistema : ""), true));
       
       
    }

    seleccionarResultadoComparador(tarjeta: any, nombreTarjeta: any, equipo: any) {
        this.setState({
            plataformas: [],
            sistemas: [],
            subsistemas: [],
            tarjetas: [],
            equipos: [],
            buscando: true,
            cursor:null
        }, () => this.props.history.push("/portada/comparador?tarjeta=" + tarjeta + "&nombre=" + encodeURI(nombreTarjeta) + (equipo ? '&equipos=["' + encodeURI(equipo) + '"]' : ""), true));
        
    }

    handleOnBlur = (event: any) => {
        setTimeout(() => {
            this.setState({
                plataformas: [],
                sistemas: [],
                subsistemas: [],
                tarjetas: [],
                equipos: [],
                buscando: true,
                cursor:null
            })
        }, 300)
    }

    getCantidadPlataformasPintados = () => {
        return this.state.plataformas.length;
    }
    getCantidadSistemasPintados = () => {
        return this.state.sistemas.length < 5 ? this.state.sistemas.length : 5;
    }
    getCantidadSubsistemasPintados = () => {
        return this.state.subsistemas.length < 5 ? this.state.subsistemas.length : 5;
    }
    getCantidadTarjetasPintados = () => {
        return this.state.tarjetas.length < 5 ? this.state.tarjetas.length : 5;
    }
    getCantidadEquiposPintados = () => {
        return this.state.equipos.length < 5 ? this.state.equipos.length : 5;
    }
    scrollToCursorActivo = () => {
        if(this.refListaResultados.current && this.refListaResultados.current?.getElementsByClassName('cursorActivo').length >0){
            this.refListaResultados.current?.getElementsByClassName('cursorActivo')[0].scrollIntoView({block: 'center'})
        }
    }

    handleKeyDown = (event: any) => {
        let totalResultados = this.getCantidadPlataformasPintados() + this.getCantidadSistemasPintados() + this.getCantidadSubsistemasPintados() + this.getCantidadTarjetasPintados() + this.getCantidadEquiposPintados();

        if(event.key === "ArrowDown"){
           
            let cursor = _.clone(_.defaultTo(this.state.cursor,-1));
            cursor = cursor+1;

            this.setState( {cursor: cursor < totalResultados  ? cursor : 0},
                () => { this.scrollToCursorActivo()})

        } else if(event.key === "ArrowUp"){

            let cursor = _.clone(_.defaultTo(this.state.cursor, totalResultados ));
            cursor = cursor - 1;

            this.setState({cursor: cursor < 0 ? totalResultados-1 : cursor},
                () => {this.scrollToCursorActivo() })

        } else if(event.key === "Enter"){
            let cursor = _.clone(this.state.cursor);

            if(cursor !== null){
                //seleccionado mediante las flechas del teclado:
                if(this.refListaResultados.current && this.refListaResultados.current?.getElementsByClassName('cursorActivo').length >0){
                    let element: HTMLElement = this.refListaResultados.current?.getElementsByClassName('cursorActivo')[0] as HTMLElement;
                    element.click();                    
                }
                
            }else{
                //se pulsa enter mientras escribes: va a todos los resultados
                this.mostrarMasResultados();
            }

        }
        
    }


    render() {
        const plataformas = this.state.plataformas;
        const sistemas = this.state.sistemas;
        const subsistemas = this.state.subsistemas;
        const tarjetas = this.state.tarjetas;
        const cursor = this.state.cursor;

        return (
            <div className="componenteBuscador">
                <input type="search" placeholder={this.props.placeholder ? this.props.placeholder : "Buscar (p. ej. 'tomas de agua' o 'Elorrio')"} onChange={this.handleInputChange} 
                    onBlur={this.handleOnBlur} onKeyDown={this.handleKeyDown} ></input>
                <div className="icono_buscar" onClick={() => this.mostrarMasResultados()}></div>
                {(plataformas.length > 0 || sistemas.length > 0 || subsistemas.length > 0 || tarjetas.length > 0 || this.state.equipos.length > 0) && (
                    <div className="resultadosBuscador">
                    <ol className="listaResultadosBuscador" ref={this.refListaResultados} role="navigation">
                    {plataformas.length > 0 && plataformas.map((plataforma:any, index: number) => (
                        <li key={index} onClick={() => this.seleccionarResultado(plataforma, null, null)} className={ cursor  ===  index ? 'cursorActivo' : ''} >
                            <span className="valor">{plataforma.nombre}</span>
                            <span className="nombre" style={{backgroundColor:'#FCE3E5', color: '#cc1d45'}}>Plataforma</span>
                        </li>
                    ))}
                    {sistemas.length > 0 && sistemas.map((sistema:any, index:number) => (
                        index < 5 && (
                        <li onClick={() => this.seleccionarResultado(sistema.plataforma, sistema.id, null)}  className={ (_.defaultTo(cursor, -1) - this.getCantidadPlataformasPintados()) ===  index ? 'cursorActivo' : ''}>
                            <span className="valor">{sistema.nombre} {sistema.plataforma.nombre}</span>
                            <span className="nombre" style={{backgroundColor:'#fcf2c3', color: '#a15b00'}}>Sistema</span>
                        </li>
                        )                        
                    ))}
                    {subsistemas.length > 0 && subsistemas.map((subsistema:any, index:number) => (
                        index < 5 && (
                        <li onClick={() => this.seleccionarResultado(subsistema.plataforma, subsistema.sistema, subsistema.id)} className={ ((_.defaultTo(cursor, -1) - this.getCantidadPlataformasPintados() - this.getCantidadSistemasPintados()) )===  index ? 'cursorActivo' : ''}>
                            <span className="valor">{subsistema.nombre} {subsistema.plataforma.nombre}</span>
                            <span className="nombre" style={{backgroundColor:'#cffbf0', color: '#2b786e'}}>Subsistema</span>
                        </li>
                        )                       
                    ))}
                    {tarjetas.length > 0 && tarjetas.map((tarjeta:any, index:number) => (
                        index < 5 && (
                            <li onClick={() => this.seleccionarResultadoComparador(tarjeta.id, tarjeta.nombre, null)}  className={ (_.defaultTo(cursor, -1) - this.getCantidadPlataformasPintados() - this.getCantidadSistemasPintados() - this.getCantidadSubsistemasPintados()) ===  index ? 'cursorActivo' : ''}>
                            <span className="valor">{tarjeta.nombre}</span>
                            <span className="nombre" style={{backgroundColor:'#E0E6FF', color: '#5349E7'}}>Tarjeta</span>
                        </li>
                        )                        
                    ))}
                    {this.state.equipos.length > 0 && this.state.equipos.map((equipo:any, index:number) => (
                        index < 5 && (
                        <li onClick={() => this.seleccionarResultadoComparador(equipo.tarjeta, equipo.tarjeta_desc, equipo.nombre)}  className={ (_.defaultTo(cursor, -1) - this.getCantidadPlataformasPintados() -this.getCantidadSistemasPintados() - this.getCantidadSubsistemasPintados() - this.getCantidadTarjetasPintados()) ===  index ? 'cursorActivo' : ''}>
                            <span className="valor">{equipo.nombre}</span>
                            <span className="nombre" style={{backgroundColor:'#E4E6EA', color: '#414147'}}>Equipo</span>
                        </li>
                        )
                    ))}
                    </ol>
                    {(sistemas.length > 4 || subsistemas.length > 4 || tarjetas.length > 4 || this.state.equipos.length > 4) && (
                        <>
                        <hr></hr>
                        <a className="botonMostrarResultados" onClick={() => this.mostrarMasResultados()}>Ver todos los resultados</a>
                        </>
                    )}
                    </div>
               )}
               {!this.state.buscando && this.state.query.length > 0 && plataformas.length == 0 && sistemas.length == 0 && subsistemas.length == 0 && tarjetas.length == 0 && this.state.equipos.length == 0 && (
                    <div className="resultadosBuscador">
                        <div className="noResultados">
                            <img src={notFound}></img>
                            <div>
                                <p style={{fontWeight: "bold", color: "var(--eroski)"}}>No hemos encontrado ningún resultado que coincida con tu búsqueda.</p>
                                <p>Inténtalo de nuevo con un término diferente.</p>
                            </div>                       
                        </div>                       
                    </div>
               )}
            </div>
        )
    }
}