import React, {  useContext, useState } from "react";


interface PropsDimmedAyuda  {
    
    paso: number; // paso en el q se activa el dimmed
    pasoActual: number | null;
    objetos: {titulo: string, descripcion: React.ReactNode}[];
    callbackActulizarPasoActual: (paso: number | null) => void;
    orientation?: "top" | "bottom" | "left" |"right"; //default right
    orientation_mobile?: "top" | "bottom" | "left" |"right"; //default orientation
    orientation_tablet?: "top" | "bottom" | "left" |"right"; //default orientation

}

const DimmedAyuda: React.FunctionComponent<PropsDimmedAyuda> = props =>  {
    const siguientePaso = () => {
        if(props.pasoActual !== null){
    
            let pasoSiguiente: number = props.pasoActual + 1;
            if(pasoSiguiente >= props.objetos.length){
                saltarPaso();
            }else{
                props.callbackActulizarPasoActual(pasoSiguiente);
            }
        }
    }
    const anteriorPaso = () => {
        if(props.pasoActual !== null){

            let pasoAnterior: number = props.pasoActual - 1;
            if(pasoAnterior >= 0){
                props.callbackActulizarPasoActual(pasoAnterior);
            }
        }
    }

    const saltarPaso = () => {
        props.callbackActulizarPasoActual(null);
    }

    return (
      <>
        {props.paso === props.pasoActual  ?
        
          <div className={'dimmed-portada' + (props.orientation ? " dimmed-"+props.orientation : "")  + (props.orientation_mobile ? " dimmed-mobile-"+props.orientation_mobile : "")  + (props.orientation_tablet ? " dimmed-tablet-"+props.orientation_tablet : "")}>
            <div className={'dimmed-portada-sombra'}>
              <div className={'dimmed-portada-margen'}>
                {props.children}
              </div>
            </div>
            <div className={"dimmed-portada-ayuda"}>
              <div>
                <div className="dimmed-portada-linea1">

                  <label className={"dimmed-portada-ayuda-titulo"}>
                    {props.objetos[props.paso]?.titulo}
                  </label>
                  <button className={"dimmed-portada-ayuda-saltar"} onClick={saltarPaso} >Saltar</button>
                </div>
                <div className="dimmed-portada-ayuda-descripcion">
                  {props.objetos[props.paso]?.descripcion}
                </div>
                <div>
                  
                    <div className="dimmed-portada-linea3">
                      <div className={'dimmed-portada-ayuda-paginacion'}>
                        {props.pasoActual + 1} / {props.objetos.length}
                      </div>
                      <div>
                        {props.pasoActual  > 0 &&
                            <button className="dimmed-portada-ayuda-anterior" onClick={anteriorPaso}></button>
                        }

                        <button className="dimmed-portada-ayuda-siguiente" onClick={siguientePaso}>{props.pasoActual +1 >= props.objetos.length ? "Entendido" : "Siguiente"}</button>
                      </div>
                    </div>
                  
                </div>
              </div>
            </div>
          </div>
      :
        props.children
      }
      </>
    )
  }
  export default DimmedAyuda;