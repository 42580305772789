import { AxiosResponse } from 'axios';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import putOnboarding from '../service/OnBoardingApiService';

export type  onBoarding ={
  visit_detalle_plataforma?: boolean | number
  visit_filtros?: boolean | number
  visit_inversiones_futuras?: boolean | number
  visit_lista_plataformas?: boolean | number
  visit_portada?: boolean | number
  visit_tarjetas?: boolean | number
 
}
export const ESTADO_ONBOARDING_COMPLETADO = false; // FALSE // no aparece onboardin
export const ESTADO_ONBOARDING_VISITAR = true; // TRUE // te salta onboarding
export const ESTADO_ONBOARDING_REPETIR = 2; // se activa desde ayuda // aparece onboarding



interface OnBoardingContextInterface {

  newUser: boolean;
  onBoarding: onBoarding;

  setOnBoardingCompletado: (onBoarding: onBoarding) => void;
  setOnBoarding: (onBoarding: onBoarding) => void;
  // setOnBoardingPortadaCompletado: (completado: boolean, onBoarding: onBoarding, onBoardingNoEditable: onBoarding | undefined) => void;
  // setOnBoardingPlataformasListadoCompletado: (completado?: boolean) => void;
  // setOnBoardingPlataformaDetalleCompletado: (completado?: boolean) => void;
  // setOnBoardingTarjetasCompletado: (completado?: boolean) => void;
  // setOnBoardingFiltrosCompletado: (completado?: boolean) => void;
  // setOnBoardingInversionFuturaCompletado: (completado?: boolean) => void;
}

export const OnBoardingContext = React.createContext<OnBoardingContextInterface>({

  newUser: false,
  onBoarding: {visit_detalle_plataforma: ESTADO_ONBOARDING_COMPLETADO,visit_lista_plataformas: ESTADO_ONBOARDING_COMPLETADO, visit_filtros:ESTADO_ONBOARDING_COMPLETADO, visit_inversiones_futuras: ESTADO_ONBOARDING_COMPLETADO, visit_portada: ESTADO_ONBOARDING_COMPLETADO, visit_tarjetas: ESTADO_ONBOARDING_COMPLETADO},
  setOnBoardingCompletado: (onBoarding: onBoarding) => {},
  setOnBoarding: (onBoarding: onBoarding) => {},

  // setOnBoardingPortadaCompletado: (completado: boolean, onBoarding: onBoarding, onBoardingNoEditable: onBoarding | undefined) => {},
  // setOnBoardingPlataformasListadoCompletado: (completado?: boolean) => {},
  // setOnBoardingPlataformaDetalleCompletado: (completado?: boolean) => {},
  // setOnBoardingTarjetasCompletado: (completado?: boolean) => {},
  // setOnBoardingFiltrosCompletado: (completado?: boolean) => {},
  // setOnBoardingInversionFuturaCompletado: (completado?: boolean) => {},
}
);

type PropsOnBoardingContextProvider = {

}

export const OnBoardingContextProvider: React.FC<PropsOnBoardingContextProvider> = (props ) => {

   
    const setOnBoarding = (onBoarding: onBoarding) => {
      setState(
        {...state, 
          onBoarding: onBoarding,
        }
      )
    }
 

    // se usa cuando se ha completado el onBoarding y para indicarlo al servidor: 
    const setOnBoardingCompletado = (onBoarding: onBoarding) => {
      
      let onboardingAPI = {
        visit_detalle_plataforma: onBoarding.visit_detalle_plataforma === true ? onBoarding.visit_detalle_plataforma : false,
        visit_filtros: onBoarding.visit_filtros === true ? onBoarding.visit_filtros : false,
        visit_inversiones_futuras: onBoarding.visit_inversiones_futuras === true ? onBoarding.visit_inversiones_futuras : false,
        visit_lista_plataformas: onBoarding.visit_lista_plataformas === true ? onBoarding.visit_lista_plataformas : false,
        visit_portada: onBoarding.visit_portada === true ? onBoarding.visit_portada : false,
        visit_tarjetas: onBoarding.visit_tarjetas === true ? onBoarding.visit_tarjetas : false,
      }
        putOnboarding(onboardingAPI).then((response: AxiosResponse) => {
          setOnBoarding(response.data);
        });
      
   }

  //  const setOnBoardingPortadaCompletado = ( completado: boolean, onBoarding: onBoarding, onBoardingNoEditable:onBoarding | undefined ) => {

  //    if(completado !== false && onBoardingNoEditable !== undefined && onBoardingNoEditable.visit_portada === true){
  //      putOnboarding({visit_portada: false}).then((response: AxiosResponse) => {
  //       setOnBoarding(response.data)
  //      });
  //    }else{
  //       setOnBoarding({...state.onBoarding, visit_portada: !completado })
  //    }
  //  }

  //  const setOnBoardingPlataformasListadoCompletado = ( ) => { 
  //     putOnboarding({visit_lista_plataformas: false}).then((response: AxiosResponse) => {
  //       console.log("onboarding completado", response.data)

  //     });
  // }
  // const setOnBoardingPlataformaDetalleCompletado = ( ) => { 
  //   putOnboarding({visit_detalle_plataforma: false}).then((response: AxiosResponse) => {
  //     console.log("onboarding completado", response.data)

  //   });
  // }
  // const setOnBoardingTarjetasCompletado = ( ) => { 
  //   putOnboarding({visit_tarjetas: false}).then((response: AxiosResponse) => {
  //     console.log("onboarding completado", response.data)

  //   });
  // }
  // const setOnBoardingFiltrosCompletado = ( ) => { 
  //   putOnboarding({visit_filtros: false}).then((response: AxiosResponse) => {
  //     console.log("onboarding completado", response.data)

  //   });
  // }
  // const setOnBoardingInversionFuturaCompletado = ( ) => { 
  //   putOnboarding({visit_inversiones_futuras: false}).then((response: AxiosResponse) => {
  //     console.log("onboarding completado", response.data)

  //   });
  // }

    const initState = {
      newUser: false,
      onBoarding: {visit_detalle_plataforma: ESTADO_ONBOARDING_COMPLETADO,visit_lista_plataformas: ESTADO_ONBOARDING_COMPLETADO, visit_filtros:ESTADO_ONBOARDING_COMPLETADO, visit_inversiones_futuras: ESTADO_ONBOARDING_COMPLETADO, visit_portada: ESTADO_ONBOARDING_COMPLETADO, visit_tarjetas: ESTADO_ONBOARDING_COMPLETADO},
      setOnBoardingCompletado: setOnBoardingCompletado,
      setOnBoarding: setOnBoarding,
      // setOnBoardingPortadaCompletado: setOnBoardingPortadaCompletado,
      // setOnBoardingPlataformasListadoCompletado: setOnBoardingPlataformasListadoCompletado,
      // setOnBoardingPlataformaDetalleCompletado: setOnBoardingPlataformaDetalleCompletado,
      // setOnBoardingTarjetasCompletado: setOnBoardingTarjetasCompletado,
      // setOnBoardingFiltrosCompletado: setOnBoardingFiltrosCompletado,
      // setOnBoardingInversionFuturaCompletado: setOnBoardingInversionFuturaCompletado,
    } 

  
    const [state, setState] = useState<OnBoardingContextInterface>(initState)
  

    return (
      <OnBoardingContext.Provider value={state}>
        {props.children}
      </OnBoardingContext.Provider>
    )
  }

  export default OnBoardingContextProvider;



