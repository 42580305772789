import _ from "lodash";
import {  useContext, useEffect } from "react";
import {ESTADO_ONBOARDING_COMPLETADO, ESTADO_ONBOARDING_VISITAR, OnBoardingContext} from "../context/OnBoardingContext";
import { PANTALLA_DETALLE_PLATAFORMA } from "../pantallas/Modelo";


interface PropsPageModeloDimmed  {
    
    pasoActual?: number | null;
    callbackIniciarPasoAyuda: (pasoAyuda: number | null) => void;
    pantalla: string | undefined;

}

const PageModeloDimmed: React.FunctionComponent<PropsPageModeloDimmed> = props =>  {
   
    const {onBoarding, setOnBoardingCompletado , setOnBoarding} = useContext(OnBoardingContext);
    
    useEffect( () => {
        if((props.pantalla !== undefined)){
            inicializarPasoAyuda();
        }
    },[props.pantalla, onBoarding] )


    useEffect( () => {
        
        if(props.pasoActual === null){
            if(props.pantalla === PANTALLA_DETALLE_PLATAFORMA){
                if(onBoarding.visit_detalle_plataforma !== ESTADO_ONBOARDING_COMPLETADO ){
                    finalizarOnboardingDetallePlataforma();
                }
            }else{
                if(onBoarding.visit_tarjetas !== ESTADO_ONBOARDING_COMPLETADO ){
                    finalizarOnboardingTarjetas();
                }
            }
        }
    },[props.pasoActual] )
      
    function inicializarPasoAyuda (){
        
        let iniciarPasoAyuda = null;
        if(props.pantalla === PANTALLA_DETALLE_PLATAFORMA){
            iniciarPasoAyuda = onBoarding.visit_detalle_plataforma === ESTADO_ONBOARDING_COMPLETADO ? null : 0;
        }else{
            iniciarPasoAyuda = onBoarding.visit_tarjetas === ESTADO_ONBOARDING_COMPLETADO ? null : 0;
        }
        if(props.pasoActual !== iniciarPasoAyuda){
            props.callbackIniciarPasoAyuda(iniciarPasoAyuda);
        }
    }

    function finalizarOnboardingDetallePlataforma(){
        let onboarding = _.clone(onBoarding);
        onboarding.visit_detalle_plataforma = false;
        if(onBoarding.visit_detalle_plataforma === ESTADO_ONBOARDING_VISITAR){
            setOnBoardingCompletado(onboarding);
        }else{
            setOnBoarding(onboarding);
        }
    }

    function finalizarOnboardingTarjetas(){
        let onboarding = _.clone(onBoarding);
        onboarding.visit_tarjetas = false;
        if(onBoarding.visit_tarjetas === ESTADO_ONBOARDING_VISITAR){
            setOnBoardingCompletado(onboarding);
        }else{
            setOnBoarding(onboarding);
        }
    }

    return (
      <>
        {props.pasoActual !== null && props.pasoActual !== undefined  ?
          <div className={'page-dimmed'}>
            {props.children}
          </div>
        :
          props.children
        }
      </>
    )
  }
  export default PageModeloDimmed;


