import React from "react";

type PropsBotonColor = {
    color: string;
    onClick?: () => void;
    mostrarVer?: boolean;
}

const BotonColorTarjeta = (props: PropsBotonColor) => {

    const clickBotonColor = (event: any) => {
        event.stopPropagation(); 
        if(props.onClick){
            props.onClick()
        }
    }

    return (
        <>
            {(props.onClick === undefined || props.mostrarVer === false ) ?
                <div className="equipo__semaforo" onClick={clickBotonColor}>
                    <div className={`equipo__semaforo__indicador ${props.color}`}>
                    </div>
                </div>
            :
                <div className="boton-ver__equipo__semaforo" onClick={clickBotonColor}>
                    <span>Ver</span>
                    <div className="equipo__semaforo">
                        <div className={`equipo__semaforo__indicador ${props.color}`}>
                        </div>
                    </div>
                </div>
            }

          
        </>
    )
}

export const BotonColorFicha = (props: PropsBotonColor) => {


    return (
        <>
            {props.onClick === undefined ?
                <span className={`ficha__semaforo ${props.color}`}></span>
            :
                <BotonColorTarjeta {...props}></BotonColorTarjeta>
                    // <div className="boton-ver__equipo__semaforo" onClick={clickBotonColor}>
                    //     <span>Ver</span>
                    //     <div className="equipo__semaforo">
                    //         <div className={`equipo__semaforo__indicador ${props.color}`}>
                    //         </div>
                    //     </div>
                    // </div>
            }

          
        </>
    )
}

export default BotonColorTarjeta;
