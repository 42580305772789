import { http } from "./utils/http";
import { AxiosResponse } from "axios";

export const descargarPlataformaCSV = () => {
    http.get('/plataformas-download/').then((response: AxiosResponse) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'export.csv'); //or any other extension
              document.body.appendChild(link);
              link.click();
    });
}

export function descargarEquiposCSV(idPlataforma?: number) {
    http.get('/equipos-download/' + (idPlataforma !== undefined ? '?plataforma=' + idPlataforma : '')).then((response: AxiosResponse) => {
        /* INICIO: obtenemos nombre del fichero */
        let headerContentDisposition = response.headers["content-disposition"];
        let filename = "export.csv";
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(headerContentDisposition);
        if (matches != null && matches[1]) { 
          filename = matches[1].replace(/['"]/g, '');
        }
        /* FIN: obtenemos nombre del fichero */
       
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
    });
}
