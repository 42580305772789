import _ from "lodash";

export function getNumeroFormateado(numero: number) {
    let numeroClone = numero;
    if(typeof numero === 'string'){
        numeroClone = _.toNumber(numero)
    }
    //PONGO AR porque es-ES los miles no los pone con puntos
    return numeroClone.toLocaleString('es-AR',
        {
            minimumFractionDigits: 2,      
            maximumFractionDigits: 2,
        }
     );
}