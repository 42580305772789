import fotoAspectoConstructivos from '../img/onboarding/onboarding-aspectos-contrs.png';
import fotoInversionesFuturas from '../img/onboarding/inversiones-futuras.png';
import fotoOrganizacion2 from '../img/onboarding/organizacion2.png';
import fotoBuscador from '../img/onboarding/buscador.png';
import fotocomparador from '../img/btn-comp1.svg';
import fotofrio from '../img/icono-frio-blanco.svg';
import fotomantenimientoEroski from '../img/mant-eroski.svg';
import fotomantenimientoExt from '../img/mant-ext.svg';
import fotoEstadoequipo from '../img/onboarding/estado-equipo.png';

export const onBoardingPasosPortada = [
  {titulo: 'Modelo', descripcion: <div>Plataforma prototipo. Muestra la información que contiene una plataforma.</div>},
  {titulo: 'Plataformas', descripcion: "Muestra todas las plataformas."},
  {titulo: 'Inversiones futuras', descripcion: "Muestra las inversiones por plataforma y dentro de las plataformas por equipo."},
  {titulo: 'Buscador', descripcion: "Búsqueda por palabras. Los resultados se ordenan por importancia."},
  {titulo: 'Ayuda', descripcion: "Puedes acceder a este botón en cualquier pantalla."},
];
export const onBoardingPasosListadoPlataforma = [
    {titulo: 'Plataformas', descripcion: "Listado de las plataformas ordenadas por provincias."},
];
export const onBoardingPasosDetallePlataforma = [
  {titulo: 'Organización de plataformas', descripcion:  <div className="onboarding-imagen-texto"><img alt="Aspectos constructivos" src={fotoAspectoConstructivos}></img><div  className="onboarding-imagen-texto__texto" ><div><span>1.</span>Sección</div><div className="mt-1rem"><span>2.</span>Subsección</div></div></div>},
  {titulo: 'Inversiones futuras', descripcion:
   <div>Muestra información acerca de las inversiones futuras y el estado de los equipos de la plataforma.
     <div className="onboarding-imagen">
        <div className="boton-ver__equipo__semaforo"><span>Ver</span><div className="equipo__semaforo"><div className="equipo__semaforo__indicador rojo"></div></div></div>
        <div className="listado-semaforos">
          <span className="ficha__semaforo verde"></span>
          <span className="ficha__semaforo amarillo"></span>
          <span className="ficha__semaforo rojo"></span>
        </div>
        <img src={fotoInversionesFuturas}></img>
     </div>
   </div>},
  {titulo: 'Información general', descripcion: "Información general acerca de la plataforma."},
  {titulo: 'Descargable', descripcion: "Descarga la información disponible de la plataforma."},
];
export const onBoardingPasosTarjetas = [
  {titulo: 'Organización de plataformas', descripcion:  <div className="onboarding-imagen-texto"><img alt="Aspectos constructivos" src={fotoAspectoConstructivos}></img><div  className="onboarding-imagen-texto__texto" ><div><span>1.</span>Sección</div><div className="mt-1rem"><span>2.</span>Subsección</div></div></div>},
  {titulo: 'Organización de plataformas', descripcion:  <div className="onboarding-imagen-texto"><img alt="Aspectos constructivos" src={fotoOrganizacion2}></img><div  className="onboarding-imagen-texto__texto" ><div className="mt-05rem"><span>3.</span>Tarjeta</div><div className="mt-05rem"><span>4.</span>Equipo</div></div></div>},
  {titulo: 'Botones tarjeta', descripcion: 
        <div className="onboarding-botones-tarjeta">
            <div>
                <div><img alt="Icono comparador" src={fotocomparador}></img></div>
                <div  className="onboarding-imagen-texto__texto" >
                    <div>
                        <span>1.</span>
                    </div>
                    <div>
                        <div>COMPARAR</div>
                        <div>Compara las tarjetas con otras plataformas.</div>
                    </div>
                </div>
            </div>
            <div>
                <div><img alt="Icono frío" src={fotofrio}></img></div>
                <div  className="onboarding-imagen-texto__texto" >
                    <div><span>2.</span></div>
                    <div><div>CONSERVACIÓN DE FRÍO</div></div>
                </div>
            </div>
            <div>
                <div><img alt="Icono mantenimiento" src={fotomantenimientoExt}></img></div>
                <div  className="onboarding-imagen-texto__texto" >
                    <div>
                        <span>3.</span>
                    </div>
                    <div>
                        <div>INFORMACIÓN MANTENEDOR</div>
                        Puede ser:
                        <ul>
                            <li><img alt="Icono mantenimiento" src={fotomantenimientoExt}></img>Externo</li>
                            <li><img alt="Icono mantenimiento eroski" src={fotomantenimientoEroski}></img>Interno</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <div><div className="boton-ver__equipo__semaforo"><span>Ver</span><div className="equipo__semaforo"><div className="equipo__semaforo__indicador amarillo"></div></div></div></div>
                <div  className="onboarding-imagen-texto__texto" >
                    <div><span>4.</span></div>
                    <div><div>ESTADO EQUIPO</div></div>
                </div>
            </div>
     
    </div>
  },
  {titulo: 'Estado equipo', descripcion: <div>Al clickar en el equipo, muestra una foto del equipo, los archivos descargables y la última actualización.
      <div className="onboarding-imagen">
        <img src={fotoEstadoequipo} alt="Foto estado equipo"></img>
      </div>
  </div>},
  {titulo: 'Barra de busqueda', descripcion: 
  <div>
    <div>
     Busca por palabras. Los resultados se ordenan por importancia.
    </div>
      <div className="onboarding-imagen">
        <img src={fotoBuscador}></img>
      </div>
      <div className="mt-05rem">
        Clickando en "Ver todos los resultados", accedes al buscador avanzado.
      </div>
   </div>},
  {titulo: 'Migas de pan', descripcion: "Indica en que apartado de la aplicación se encuentra el usuario."},
];
export const onBoardingPasosInversionesFuturas = [
  {titulo: 'Plataformas y presupuesto', descripcion: "Proporciona Información acerca de las inversiones futuras por plataforma."},
  {titulo: 'Equipo y presupuesto', descripcion: "Muestra las inversiones previstas para los equipos de la plataforma seleccionada."},
  {titulo: 'Estado del equipo', descripcion: "Muestra las incidencias del equipo."},
];
export const onBoardingPasosFiltros = [
  {titulo: 'Filtros', descripcion: "Filtra el resultado de la búsqueda."},
  {titulo: 'Ver tarjetas', descripcion: <div>Al clickar en "Ver" se abre la tarjeta seleccionada.</div>},
];