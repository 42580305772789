import React, { useEffect } from 'react';
import LoginApiService from "../service/LoginApiService";

const authContext = React.createContext({} as useAuthType);

interface useAuthType {
    authed: boolean,
    loginSiSesionAnterior: () => void,
    logout: () => void,
    login: () => void,
}


function useAuth() {
  const [authed, setAuthed] = React.useState(false);

    /* INICIO: WSO2IS */
    function loginSiSesionAnterior () {
        // See if there is a valid session.
        let isLogged = window.sessionStorage.getItem('isLogged');
        let token = window.sessionStorage.getItem('token')
        if(isLogged === "true" && token !== null){
          LoginApiService.isTokenValid()
            .then((response:any )=> {
                setAuthed(true);
            })
            .catch((error => {
                logout();
            }));
        }else{
            setAuthed(false)
        }
            
        return;
        
    }
    function login() {
        //SE LLAMA AL HACER LOGIN
        setAuthed(true);
    }
    function logout() {
        window.sessionStorage.clear()
        // window.sessionStorage.removeItem('username');
        // window.sessionStorage.removeItem('token')
        setAuthed(false);
    }
  


  return {
    authed,
    loginSiSesionAnterior,
    login,
    logout,
    
  };
}

interface StoreProviderProps {
    children: React.ReactElement;
}

export function AuthProvider({children}: StoreProviderProps):  React.ReactElement {
  const auth = useAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}