import React, { useState } from "react";
import { ModalAyuda } from "./ModalAyuda";

const BotonAyuda = () => {

    const [ mostrarModal, setMostrarModal] = useState<boolean>(false);
    
    const abrirModal = () => {
        setMostrarModal(true)
    }
    const cerraModal = () => {
        setMostrarModal(false)
    }

    return (
        <>
            <button className="btn-ayuda" onClick={abrirModal}><span></span>Ayuda</button>
            {mostrarModal &&
                <ModalAyuda onCerrarModal={cerraModal}></ModalAyuda>
            }
        </>
    )
}
export default BotonAyuda;