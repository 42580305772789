import axios from "axios";

export const URL_API = 'https://plataformas-api.herokuapp.com'

/**
 * axios instancia
 */
 let instance = axios.create({
    baseURL: URL_API,
    headers: {
        "Content-type": "application/json",
    }
});




export const http = instance;