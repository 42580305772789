import {  useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
// import logo from '../logo.svg';
import './Portada.css';
import linkExterno from '../img/icono-link.svg';
import { Buscador } from "../componentes/Buscador";
import axios, { AxiosResponse } from "axios";
import { ModalHistoricoInversiones } from "../componentes/ModalHistoricoInversiones";
import { useHistory } from "react-router-dom";
import {isRoleAdminEroski, RoleContext} from "../context/RoleContext";
import BotonAyuda from "../componentes/BotonAyuda";
import DimmedAyuda from "../componentes/DimmedAyuda";
import { ESTADO_ONBOARDING_COMPLETADO, ESTADO_ONBOARDING_REPETIR, ESTADO_ONBOARDING_VISITAR, OnBoardingContext } from "../context/OnBoardingContext";
import { onBoardingPasosPortada } from "../constantes/onBoarding";
import _ from "lodash";
import BotonListaDocumentacion from "../componentes/BotonListaDocumentacion";

interface PropPortada extends RouteComponentProps {

}

export const URL_MODELO = "/portada/modelo/2?sistema=1";
export const URL_PLATAFORMAS = "/portada/plataformas";

export const Portada = (props: PropPortada) => { 
  const history = useHistory();
  const {groups} = useContext(RoleContext);
  const {onBoarding, setOnBoarding, setOnBoardingCompletado} = useContext(OnBoardingContext);

  const [mostrarModalMantenimiento, setMostrarModalMantenimiento] = useState<boolean>(false)
  const [mostrarModalInversiones, setMostrarModalInversiones] = useState<boolean>(false)
  const [pasoAyuda, setPasoAyuda] = useState<number | null >(onBoarding.visit_portada === ESTADO_ONBOARDING_COMPLETADO ? null : 0);
  
  let onBoardingPasos = _.clone(onBoardingPasosPortada);
 
  // si no es admin tenemos que quitar un paso, porque no mostramos las inversiones futuras
  if( !isRoleAdminEroski(groups) ){
    onBoardingPasos.splice(2, 1); 
  }
  useEffect( () => {
    if(onBoarding.visit_portada === ESTADO_ONBOARDING_COMPLETADO){
      setPasoAyuda(null)
    }else{
      setPasoAyuda(0)
    }
    // si inversiones futuras tiene el estado de repetir se abre el modal:
    if(onBoarding.visit_inversiones_futuras === ESTADO_ONBOARDING_REPETIR){
      setMostrarModalInversiones(true);
    }
   
  }, [onBoarding]);

  useEffect( () => {
    if(onBoarding.visit_portada !== ESTADO_ONBOARDING_COMPLETADO && pasoAyuda === null){
      finalizarOnboarding();
    }
   
  }, [pasoAyuda])

  const handleModelo = () => {
    history.push(URL_MODELO)
  }

  const handlePlataformas = () => {
    history.push(URL_PLATAFORMAS)
  }

  function handleEnlaceExternoLinx() {
    window.open("http://172.22.41.91:3002/", "_blank")
  }

  function handleEnlaceExternoCofriView() {
    window.open("https://co2sttem.co2smarttech.com/Login/Index/?p=Cofrico", "_blank")
  }

  function handleEnlaceExternoInversionesFuturas() {
    window.open("http://172.22.41.91:3002/", "_blank")
  }

  const abrirModalMantenimiento = () => {
    setMostrarModalMantenimiento(true)
  }
  const abrirModalInversiones = () => {
    setMostrarModalInversiones(true);
  }
  const cerrarModalMantenimiento = () => {
    setMostrarModalMantenimiento(false)
  }
  const cerrarModalInversiones = () => {
    setMostrarModalInversiones(false);
  }

  const descargarCSV = () => {
    axios.get('/plataformas-download/').then((response: AxiosResponse) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
    })
  }



    const finalizarOnboarding = () => {
      let onboarding = _.clone(onBoarding);
      onboarding.visit_portada = false;
      if(onBoarding.visit_portada === ESTADO_ONBOARDING_VISITAR){
        setOnBoardingCompletado(onboarding);
      }else{
        setOnBoarding(onboarding);
      }
    }

        return (
          <div className={"login-page " + (pasoAyuda !== null ? "page-dimmed" : "")}>
            <div className="modal-mantenimiento" style={{display: mostrarModalMantenimiento ? 'block' : 'none'}}>
              <div className="cerrar-modal" onClick={cerrarModalMantenimiento}></div>
              <div className="modal__grupo-botones">
                <button className="login-button" onClick={handleEnlaceExternoLinx}>Linx<img className="link-externo-portada" src={linkExterno}alt="icono de enlace a página externa"></img><span className="flecha-derecha"></span></button>
                <button className="login-button" onClick={handleEnlaceExternoCofriView}>Cofriview<img className="link-externo-portada" src={linkExterno} alt="icono de enlace a página externa"></img><span className="flecha-derecha"></span></button>
              </div>
            </div>
            {mostrarModalInversiones &&
              <ModalHistoricoInversiones onCerrarModal={cerrarModalInversiones}></ModalHistoricoInversiones>
            }
            <div className="login-bg">
                <div className="login-area">
                  <div className="login-area-blur"></div>
                  <div className="login-header">
                    <h1>Libro de plataformas</h1>
                  </div>
                  <DimmedAyuda paso={ isRoleAdminEroski(groups) ? 3 : 2} pasoActual={pasoAyuda} orientation_mobile="bottom" callbackActulizarPasoActual={setPasoAyuda} objetos={onBoardingPasos} >
                    <div className="formulario">
                      <label htmlFor="user">Buscar por equipo o plataforma</label>
                      <Buscador {...props} placeholder={"P. ej.: Tomas de agua o Elorrio"}/>
                    </div>
                  </DimmedAyuda>
                  <div className="portada-nav">
                    <DimmedAyuda paso={0} pasoActual={pasoAyuda} orientation_mobile="bottom" callbackActulizarPasoActual={setPasoAyuda}  objetos={onBoardingPasos}  >
                      <button className="login-button" onClick={handleModelo}>Modelo<span className="flecha-derecha"></span></button>
                    </DimmedAyuda>
                    <DimmedAyuda paso={1} pasoActual={pasoAyuda} orientation_mobile="top" callbackActulizarPasoActual={setPasoAyuda}  objetos={onBoardingPasos}>
                      <button className="login-button" onClick={handlePlataformas}>Plataformas<span className="flecha-derecha"></span></button>
                    </DimmedAyuda>
                    <button className="login-button" onClick={abrirModalMantenimiento}>Mantenimiento<span className="flecha-derecha"></span></button>
                    {isRoleAdminEroski(groups) &&
                      <DimmedAyuda paso={2} pasoActual={pasoAyuda} orientation_mobile="top" callbackActulizarPasoActual={setPasoAyuda}  objetos={onBoardingPasos} 
                         >
                        <button className="login-button" onClick={abrirModalInversiones}>Inversiones futuras<span className="flecha-derecha"></span></button>
                      </DimmedAyuda>
                    }
                  </div>
                  <div className="login-logo-eroski"></div>
                </div>  
                <DimmedAyuda paso={isRoleAdminEroski(groups) ? 4 : 3} pasoActual={pasoAyuda} orientation_mobile="top"  orientation={"top"}  callbackActulizarPasoActual={setPasoAyuda}  objetos={onBoardingPasos} >
                  <BotonAyuda></BotonAyuda>
                </DimmedAyuda>

            </div>
            <BotonListaDocumentacion></BotonListaDocumentacion>
          </div>

        );
   
}



