import React, { Component, useContext, useEffect, useState } from 'react'

import './Login.css';
import axios from 'axios'
import { RouteComponentProps, useHistory } from "react-router-dom";
import { RoleContext } from '../context/RoleContext';
import { OnBoardingContext } from '../context/OnBoardingContext';
import  useAuth  from "../context/authContext";
import LoginApiService from '../service/LoginApiService';


interface PropsLogin extends RouteComponentProps {

}

type StateLogin = {
    user: string;
    password: string;
    erroresValidacion: string | null;
}

export const Login = (props: PropsLogin) => {

    const [user, setUser] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [erroresValidacion, setErroresValidacion] = useState<string | null>(null);

    const history = useHistory();
    const {groups, setGroups} = useContext(RoleContext);
    const {onBoarding, newUser, setOnBoarding} = useContext(OnBoardingContext);
    const { authed, login } = useAuth();
    // state = {
    //     //user : "MIKELSERRANO",
    //     //password : "¡InfoBox!",
    //     user : "",
    //     password : "",
    //     erroresValidacion : null
    // }

    useEffect( () => {
        if(authed === true){
            history.push("/portada");
        }
    },[authed])



    function handleLogin() {
        let erroresValidacion = validarFormulario();
        if (erroresValidacion === "") {        
            enviarLogin();        
        } else {
            setErroresValidacion(erroresValidacion)
          
        }
    }

    function handleUserInput (e: any) {
        const name = e.target.name;
        const value = e.target.value;
        if (name == "user") {
            setUser(value)
        } else {
            setPassword(value)

        }
      }

      const enviarLogin = () => {
        LoginApiService.loginApi(user, password)
            .then((response) => {
                if (response.status === 200) {
                    window.sessionStorage.setItem("isLogged", "true");
                    window.sessionStorage.setItem("token", response.data.key);
                    window.sessionStorage.setItem("username", user);   
                    login(); //LOGIN EN AUTHCONTEXT
                    setGroups(response.data.groups);
                    setOnBoarding(response.data.onboarding);        
                } else {
                    setErroresValidacion("<p>* Los datos introducidos no son correctos</p>")
                }
            }, (error) => {
                setErroresValidacion("<p>* Los datos introducidos no son correctos</p>")
        });
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            handleLogin() 
        }
    }

    function validarFormulario() {
        let erroresValidacion = "";
        if (!user || user.trim().length === 0) {
            erroresValidacion += "<p>* Introduce el usuario</p>"
        }

        if (!password || password.trim().length === 0) {
            erroresValidacion += "<p>* Introduce la contraseña</p>"
        }

        return erroresValidacion;
    }

    const handleCerrarPopUp = () => {
        setErroresValidacion(null);
    }
    
    // render() {
        return (
            <div className="login-page">
                <div className="login-bg">
                    <div className="login-area">
                        <div className="login-area-blur"></div>
                        <div className="login-header">
                            <h1>Libro de plataformas</h1>
                            <p>Identifícate con tus credenciales de Eroski. Recuerda que debes utilizar una cuenta autorizada.</p>
                        </div>
                        <div className="formulario">
                            <label htmlFor="user">Nombre Eroski (p. ej.: S012345)</label>
                            <input type="text" name="user" id="user" value={user} onChange={(event) => handleUserInput(event)}></input>
                            <label htmlFor="password">Contraseña</label>
                            <input type="password" name="password" id="password" value={password} onChange={(event) => handleUserInput(event)} onKeyDown={handleKeyDown}></input>
                            {erroresValidacion && (
                                <div style={{color: 'red'}} dangerouslySetInnerHTML={{__html: erroresValidacion! as string}}></div>
                            )}
                            <button className="login-button" onClick={() => handleLogin()}>Entrar<span className="flecha-derecha"></span></button>
                        </div>                       
                        <div className="login-logo-eroski"></div>
                    </div>    
                </div>
            </div>
        )
    // }
}

export default Login;




