import axios, { AxiosResponse } from "axios";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { MenuSistemaSubsistema } from "../componentes/MenuSistemaSubsistema";
import _ from 'lodash';
import { Tarjeta } from "../componentes/Tarjeta";
import { FichaPlataforma } from "../componentes/FichaPlataforma";
import { Buscador } from "../componentes/Buscador";
import PageModeloDimmed from "../componentes/PageModeloDimmed";
import DimmedAyuda from "../componentes/DimmedAyuda";
import { onBoardingPasosDetallePlataforma, onBoardingPasosTarjetas } from "../constantes/onBoarding";
// import logo from '../logo.svg';
interface RouteParams {
  id: string
}

interface PropModelo extends RouteComponentProps<RouteParams>{

}

type StateModelo = {
  plataformas: any[] | [];
  idPlataformaSeleccionada: number | null;
  sistemasSubsistemas: any[] | [];
  idSistemaDesplegado: number | null;
  idSubsistemaSeleccionado: number | null;
  tarjetas: any[] | [];
  detallePlataforma: any | null;
  isMobile: boolean;
  lateralVisible: boolean;
  idEquipoSeleccionado?: number | null;
  idEquipoDetalleModalSeleccionado?: number | null;
  pasoAyuda?: number | null;
  pantalla: string | undefined;
}

export const PANTALLA_DETALLE_PLATAFORMA = 'detalle-plataforma';
export const PANTALLA_TARJETAS = 'tarjetas';

export class Modelo extends Component<PropModelo, StateModelo> { 

    state = {
      plataformas: [],
      idPlataformaSeleccionada: null,
      sistemasSubsistemas: [],
      idSistemaDesplegado: null,
      idSubsistemaSeleccionado: null,
      tarjetas: [],
      detallePlataforma: null,
      isMobile: window.innerWidth < 860,
      lateralVisible: window.innerWidth < 860 ? false : true,
      idEquipoSeleccionado: null,
      idEquipoDetalleModalSeleccionado: null,
      onboarding: false,
      pasoAyuda: undefined,
      pantalla: undefined
    }

    setPasoAyuda = (pasoAyuda: number | null) => {
      this.setState({pasoAyuda: pasoAyuda})
    }

    handleWindowResize = () => {
      this.setState(
        { isMobile: window.innerWidth < 860,
          lateralVisible: window.innerWidth < 860 ? false : true
        } 
      );
    }

    getParametroURLQuery = (variable: string) => {
      var query = this.props.location.search.substring(1);
      var vars = query.split("&");
      for (var i=0;i<vars.length;i++) {
              var pair = vars[i].split("=");                   
              if(pair[0] == variable){return pair[1];}
      }
      return(false);
    } 

    componentDidMount() {     
      let filtrosBusqueda = this.props.location.search;
      var sistema = null;
      var subsistema = null;
      var equipo = null;
      var equipoDetalleModal = null;
      // console.log(this.state.onboarding)
      if (filtrosBusqueda !== "") {
          sistema = this.getParametroURLQuery("sistema");
          subsistema = this.getParametroURLQuery("subsistema");            
          equipo = this.getParametroURLQuery("equipo");   
          equipoDetalleModal = this.getParametroURLQuery("modal-equipo");           
        this.setState({pantalla: PANTALLA_TARJETAS});

      }else{
        this.setState({pantalla: PANTALLA_DETALLE_PLATAFORMA});
      }
      this.recuperarPlataformas(this.props.match.params.id ? Number(this.props.match.params.id): null, sistema ? Number(sistema) : null, subsistema ? Number(subsistema) : null , equipo ? Number(equipo): null, equipoDetalleModal ? Number(equipoDetalleModal): null);
      window.addEventListener('resize', this.handleWindowResize);
    }

    componentDidUpdate(prevProps: PropModelo, prevState: StateModelo) {
      if (prevProps.location.pathname != this.props.location.pathname || prevProps.location.search != this.props.location.search) {
        let filtrosBusqueda = this.props.location.search;
        var sistema = null;
        var subsistema = null;
        var equipo = null;
       var equipoDetalleModal = null;
      if (filtrosBusqueda !== "") {
            sistema = this.getParametroURLQuery("sistema");
            subsistema = this.getParametroURLQuery("subsistema");            
            equipo = this.getParametroURLQuery("equipo");            
            equipoDetalleModal = this.getParametroURLQuery("modal-equipo");    
             this.setState({pantalla: PANTALLA_TARJETAS});

          }else{
            this.setState({pantalla: PANTALLA_DETALLE_PLATAFORMA});

          }
        if (Number(this.props.match.params.id) != prevState.idPlataformaSeleccionada 
          || sistema != prevState.idSistemaDesplegado
          || subsistema != prevState.idSubsistemaSeleccionado
          || ( equipo!= '' && equipo != prevState.idEquipoSeleccionado)
        ) {
        this.recuperarPlataformas(this.props.match.params.id ? Number(this.props.match.params.id): null, sistema ? Number(sistema) : null, subsistema ? Number(subsistema) : null , equipo ? Number(equipo): null, equipoDetalleModal ? Number(equipoDetalleModal): null);
        }
      }

    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowResize);
    }

    cambiarURL = (plataforma: number) => {
      this.props.history.replace("/portada/modelo/" + plataforma 
      + (this.state.idSistemaDesplegado && this.state.idSubsistemaSeleccionado ? "?" : "") 
      + (this.state.idSistemaDesplegado && this.state.idSubsistemaSeleccionado ? "sistema=" + this.state.idSistemaDesplegado : "")
      + (this.state.idSistemaDesplegado && this.state.idSubsistemaSeleccionado ? "&subsistema=" + this.state.idSubsistemaSeleccionado : "")
      + (this.state.idSistemaDesplegado && this.state.idSubsistemaSeleccionado && this.state.idEquipoSeleccionado ? "&equipo=" + this.state.idEquipoSeleccionado : "")
      + (this.state.idSistemaDesplegado && this.state.idSubsistemaSeleccionado && this.state.idEquipoDetalleModalSeleccionado ? "&modal-equipo=" + this.state.idEquipoDetalleModalSeleccionado : "")
      )
    }

    recuperarPlataformas(idPlataforma: number | null, idSistema: number | null, idSubsistema: number | null, idEquipo: number | null, idEquipoModal: number | null) {
      axios.get('/lista-plataforma/').then((response: AxiosResponse) => {
        this.setState({
           plataformas: response.data,
           idPlataformaSeleccionada: idPlataforma ? idPlataforma: response.data.length > 0 ? response.data[0].id : null,
           detallePlataforma: null
        }, () => {          
            this.recuperarSubsistemas(idPlataforma, idSistema, idSubsistema, idEquipo, idEquipoModal)          
        })
      })
    }

    /*recuperarSubsistemasOld(idPlataforma: number | null, idSistema: number | null, idSubsistema: number | null) {
      axios.get('/lista-sistema-subsistema/').then((response: AxiosResponse) => {
        let sistemaBuscado = null;
        if (response.data && response.data.length > 0 && idSistema) {
          sistemaBuscado = _.find(response.data, {id: idSistema})
        }
        this.setState({
           sistemasSubsistemas: response.data,
           idSubsistemaSeleccionado: !idPlataforma && response.data.length > 0 ? response.data[0].subsistemas[0].id : idSubsistema ? idSubsistema : idSistema && sistemaBuscado ? sistemaBuscado.subsistemas[0].id : null,
           idSistemaDesplegado: !idSistema ? null : idSistema
        }, () => idPlataforma && !idSistema && !idSubsistema? this.recuperarFichaPlataforma() : this.recuperarTarjeta())
      })
    }*/

    recuperarSubsistemas(idPlataforma: number | null, idSistema: number | null, idSubsistema: number | null, idEquipo: number | null, idEquipoModal: number | null) {
      axios.get('/plataforma-schema/?plataforma=' + this.state.idPlataformaSeleccionada).then((response: AxiosResponse) => {
        let sistemaBuscado = null;
        if (response.data && response.data.length > 0 && idSistema) {
          sistemaBuscado = _.find(response.data, {id: idSistema})
        }
        this.setState({
           sistemasSubsistemas: response.data,
           idSubsistemaSeleccionado: !idPlataforma && response.data.length > 0 ? response.data[0].subsistemas[0].id : idSubsistema ? idSubsistema : idSistema && sistemaBuscado ? sistemaBuscado.subsistemas[0].id : null,
           idSistemaDesplegado: !idSistema ? null : idSistema,
           idEquipoSeleccionado: !idEquipo ? null : idEquipo,
           idEquipoDetalleModalSeleccionado: !idEquipoModal ? null : idEquipoModal,
        }, () => idPlataforma && !idSistema && !idSubsistema? this.recuperarFichaPlataforma() : this.recuperarTarjeta())
      })
    }
    

    /*recuperarTarjetaOld() {
      axios.get('/lista-tarjeta/', {params: {subsistema: this.state.idSubsistemaSeleccionado}}).then((response: AxiosResponse) => {
        this.setState({
          tarjetas: response.data
        }, () => {this.recuperarEquipos(); this.cambiarURL(this.state.idPlataformaSeleccionada!)})
      })
    }*/

    recuperarTarjeta() {
      let instancia = this;
      let sistemaBuscado: any = _.find(this.state.sistemasSubsistemas, function(sistema: any) {
        return _.some(sistema.subsistemas, function(subsistema: any) {
            return subsistema.id == instancia.state.idSubsistemaSeleccionado;
        });
      });    
      if (sistemaBuscado) {
        let subSistemaBuscado = _.find(sistemaBuscado.subsistemas, {id: instancia.state.idSubsistemaSeleccionado});
         if (subSistemaBuscado) {
           
          // this.setState({
          //     tarjetas: subSistemaBuscado.tarjetas
          //  }, () => {this.recuperarEquipos(subSistemaBuscado.tarjetas); this.cambiarURL(this.state.idPlataformaSeleccionada!)})
          this.recuperarEquipos(subSistemaBuscado.tarjetas);
          this.cambiarURL(this.state.idPlataformaSeleccionada!)
         } else {
          this.onFichaPlataforma();
         } 
     } else {
        this.onFichaPlataforma();
     }
    }

    recuperarFichaPlataforma() {
      axios.get('/detalle-plataforma/' + this.state.idPlataformaSeleccionada + "/").then((response: AxiosResponse) => {
        this.setState({
          detallePlataforma: response.data
        })
      })
    }

    recuperarEquipos(tarjetasParaRecuperarEquipos: any) {
      let urlsEquipos = [];
      if (tarjetasParaRecuperarEquipos.length > 0){
        let instancia = this;
        axios.all(tarjetasParaRecuperarEquipos.map((tarjeta: any) => axios.get("/lista-equipo/", { params: {plataforma: this.state.idPlataformaSeleccionada, tarjeta: tarjeta.id}})))
        .then(axios.spread(function (...res) {
          let tarjetas: any[] = _.clone(tarjetasParaRecuperarEquipos);
          let tarjetasEncontradas: any[] = [];
          _.each(res, (peticion : any) => {
            if (peticion.data) {
              let tarjetaID = peticion.config.params.tarjeta;
              let datos = peticion.data;
              let tarjetaBuscada = _.find(tarjetas, {id: tarjetaID});
              if (tarjetaBuscada) {
                tarjetaBuscada.equipos = datos;
                tarjetasEncontradas.push(tarjetaBuscada)
              }
            }            
          })
          instancia.setState({
            tarjetas: tarjetasEncontradas
          });
        }))
      }
    }

    onVolver = () => {
      this.props.history.goBack();
    }

    onVolverPortada = () => {
      this.props.history.push("/portada")
    }

    onVolverPlataformas = () => {
      this.props.history.push("/portada/plataformas")
    }

    onFichaPlataforma = () => {
      this.props.history.replace("/portada/modelo/" + this.state.idPlataformaSeleccionada, true)
    }

    /*onChangePlataformaOld = (event: React.FormEvent<HTMLSelectElement>) => {
      if (this.state.idSubsistemaSeleccionado) {
        this.setState({
          idPlataformaSeleccionada: Number(event.currentTarget.value),
          detallePlataforma: null
        }, () => this.recuperarTarjeta())
      } else {
        this.cambiarURL(Number(event.currentTarget.value));
      }
    }*/

    onChangePlataforma = (event: React.FormEvent<HTMLSelectElement>) => {
      if (this.state.idSubsistemaSeleccionado) {
        this.setState({
          idPlataformaSeleccionada: Number(event.currentTarget.value),
          detallePlataforma: null,
          idEquipoSeleccionado: null,
          idEquipoDetalleModalSeleccionado: null,
          }, () => this.recuperarSubsistemas(this.state.idPlataformaSeleccionada, this.state.idSistemaDesplegado, this.state.idSubsistemaSeleccionado, this.state.idEquipoSeleccionado, this.state.idEquipoDetalleModalSeleccionado ))
      } else {
        this.cambiarURL(Number(event.currentTarget.value));
      }
    }

    onChangeSubsistema = (subsistema: number) => {
      this.setState({
        idSubsistemaSeleccionado: subsistema,
        detallePlataforma: null,
        lateralVisible: false,
        idEquipoSeleccionado: null,
        idEquipoDetalleModalSeleccionado: null,
      }, () => this.recuperarTarjeta())
    }

    onDesplegarSistema = (sistema: number | null) => {
      this.setState({
        idSistemaDesplegado: sistema
      })
    }

    toggleLateral = () => {
      this.setState({
        lateralVisible: !this.state.lateralVisible
      })
    }

    getNombrePlataformaSeleccionada = () => {
      if (this.state.idPlataformaSeleccionada && this.state.plataformas.length > 0) {
        let plataformaBuscada: any = _.find(this.state.plataformas, {id: this.state.idPlataformaSeleccionada});
        if (plataformaBuscada) {
          return plataformaBuscada.nombre;
        } else return "Modelo"
      } else return "Modelo"
    }

    getNombreSistemaSeleccionado = () => {
      if (this.state.idSistemaDesplegado && this.state.idSubsistemaSeleccionado && this.state.sistemasSubsistemas.length > 0) {
        let instancia = this;
        let sistemaBuscado: any = _.find(this.state.sistemasSubsistemas, function(sistema: any) {
            return _.some(sistema.subsistemas, function(subsistema: any) {
                return subsistema.id == instancia.state.idSubsistemaSeleccionado ;
            });
        });
        if (sistemaBuscado) {
          return sistemaBuscado.nombre;
        } else return ""
      } else return ""
    }


    onBreadcrumSistema = () => {
      if (this.state.idSistemaDesplegado && this.state.idSubsistemaSeleccionado && this.state.sistemasSubsistemas.length > 0) {
        let instancia = this;
        let sistemaBuscado: any = _.find(this.state.sistemasSubsistemas, function(sistema: any) {
            return _.some(sistema.subsistemas, function(subsistema: any) {
                return subsistema.id == instancia.state.idSubsistemaSeleccionado ;
            });
        });
        if (sistemaBuscado) {
          this.props.history.replace("/portada/modelo/" + this.state.idPlataformaSeleccionada
          + "?sistema=" + sistemaBuscado.id, true)
        }
      }
    }

    getNombreSubSistemaSeleccionado = () => {
      if (this.state.idSistemaDesplegado && this.state.idSubsistemaSeleccionado && this.state.sistemasSubsistemas.length > 0) {
        let instancia = this;
        let sistemaBuscado: any = _.find(this.state.sistemasSubsistemas, function(sistema: any) {
            return _.some(sistema.subsistemas, function(subsistema: any) {
                return subsistema.id == instancia.state.idSubsistemaSeleccionado ;
            });
        });
        if (sistemaBuscado) {
          let subSistemaBuscado: any = _.find(sistemaBuscado.subsistemas, {id: this.state.idSubsistemaSeleccionado});
          if (subSistemaBuscado) {
            return subSistemaBuscado.nombre;
          } else return "";         
        } else return "";
      } else return "";
    }

    

    render() {
      let pasoAyuda: number | null = this.state.pasoAyuda=== undefined ? null : this.state.pasoAyuda! as number | null;

        return (
          <PageModeloDimmed pasoActual={this.state.pasoAyuda} callbackIniciarPasoAyuda={this.setPasoAyuda} pantalla={this.state.pantalla}>

            <div className={"libro"  + (this.state.pantalla === PANTALLA_DETALLE_PLATAFORMA ? " page-detalle" : " page-tarjetas") }>
              <div className={"topnav" + ((pasoAyuda === 4 || pasoAyuda === 5) ? " topnav-no-dimmed" : "")}>
                  <DimmedAyuda  paso={5} orientation="bottom" pasoActual={ this.state.pantalla === PANTALLA_TARJETAS? pasoAyuda: null} callbackActulizarPasoActual={this.setPasoAyuda} objetos={onBoardingPasosTarjetas} >
                <div className="topnav__nav"> 

                    <button className="btn-volver" onClick={this.onVolver}></button>
                    <div className="breadcrumb">
                      <a className="breadcrumb__portada" onClick={this.onVolverPortada}>Portada</a>
                      /
                      <a onClick={this.onVolverPlataformas}>Plataformas</a>
                      /
                      <a className="breadcrumb__activo" onClick={this.onFichaPlataforma}>{this.getNombrePlataformaSeleccionada()}</a>
                      {!this.state.detallePlataforma && this.getNombreSubSistemaSeleccionado() != "" && (
                        <>
                        /
                        <a className="" onClick={this.onBreadcrumSistema}>{this.getNombreSistemaSeleccionado()}</a>
                        {this.state.idSubsistemaSeleccionado && (
                          <>
                          /
                          <a className="">{this.getNombreSubSistemaSeleccionado()}</a>
                          </>
                        )}
                        </>
                      )}
                    </div>
              
                </div>
                  </DimmedAyuda>
                  <div className="topnav__buscador">
                <DimmedAyuda  paso={4} orientation="bottom" pasoActual={ this.state.pantalla === PANTALLA_TARJETAS? pasoAyuda: null} callbackActulizarPasoActual={this.setPasoAyuda} objetos={onBoardingPasosTarjetas} >
                    <Buscador {...this.props}/>
                </DimmedAyuda>
                  </div>
                <div className="topnav-logo-eroski"></div>
              </div>

              <div className="sidenav" style={{display: !this.state.isMobile || this.state.lateralVisible ? 'block' : 'none'}}>
                <DimmedAyuda  paso={0} pasoActual={!this.state.isMobile && this.state.pantalla === PANTALLA_DETALLE_PLATAFORMA ? pasoAyuda : null}  callbackActulizarPasoActual={this.setPasoAyuda} objetos={onBoardingPasosDetallePlataforma} >
                  <DimmedAyuda  paso={0} pasoActual={!this.state.isMobile && this.state.pantalla === PANTALLA_TARJETAS ? pasoAyuda : null} callbackActulizarPasoActual={this.setPasoAyuda} objetos={onBoardingPasosTarjetas} >

                    <button className="btn-cerrar" onClick={this.toggleLateral}></button>
                    <div className="sidenav__selector">
                      <label>Cambiar de plataforma</label>
                      <select onChange={this.onChangePlataforma} value={this.state.idPlataformaSeleccionada != null ? Number(this.state.idPlataformaSeleccionada) : undefined}>
                        {this.state.plataformas.length > 0 && this.state.plataformas.map((plataforma: any) =>
                          <option key={plataforma.id} value={plataforma.id}>{plataforma.nombre}</option>
                        )}
                      </select>
                    </div>
                    {this.state.sistemasSubsistemas.length > 0 && this.state.sistemasSubsistemas.map((sistema: any) =>
                      <MenuSistemaSubsistema idSubsistemaSeleccionado={this.state.idSubsistemaSeleccionado} idSistemaDesplegado={this.state.idSistemaDesplegado}
                      sistema={sistema} onChangeSubsistema={this.onChangeSubsistema} 
                      onDesplegarSistema={this.onDesplegarSistema}/>
                    )}
                    </DimmedAyuda>
                  </DimmedAyuda>
              </div>

              <div className="main" style={{display: !this.state.isMobile || !this.state.lateralVisible ? 'block' : 'none'}}>
                <div className="container_btn-menu">
                  <DimmedAyuda  paso={0} pasoActual={ this.state.isMobile && this.state.pantalla === PANTALLA_DETALLE_PLATAFORMA ? pasoAyuda : null} orientation_mobile="bottom" callbackActulizarPasoActual={this.setPasoAyuda} objetos={onBoardingPasosDetallePlataforma} >
                    <DimmedAyuda  paso={0} pasoActual={ this.state.isMobile && this.state.pantalla === PANTALLA_TARJETAS ? pasoAyuda : null} orientation_mobile="bottom" callbackActulizarPasoActual={this.setPasoAyuda} objetos={onBoardingPasosTarjetas} >
                      <button className="btn-menu" onClick={this.toggleLateral}></button>
                    </DimmedAyuda>
                  </DimmedAyuda>
                </div>
                {this.state.detallePlataforma ? (
                  <FichaPlataforma {...this.props} plataforma={this.state.detallePlataforma} pasoAyuda={pasoAyuda} callbackActualizarPasoAyuda={this.setPasoAyuda} />
                ) : (
                  <div className="main__tarjetas">
                  {this.state.tarjetas.length > 0 && this.state.tarjetas.map((tarjeta: any, index: number) =>
                    tarjeta.equipos  && 
                        <DimmedAyuda  paso={1} orientation="left" orientation_mobile="top" pasoActual={index === 0  && this.state.pantalla === PANTALLA_TARJETAS ? pasoAyuda: null} callbackActulizarPasoActual={this.setPasoAyuda} objetos={onBoardingPasosTarjetas} >
                          <DimmedAyuda  paso={2}  orientation_mobile="bottom" orientation_tablet="bottom" pasoActual={index === 0  && this.state.pantalla === PANTALLA_TARJETAS ? pasoAyuda: null} callbackActulizarPasoActual={this.setPasoAyuda} objetos={onBoardingPasosTarjetas} >
                            <DimmedAyuda  paso={3}  orientation_mobile="bottom" orientation_tablet="bottom" pasoActual={index === 0 && this.state.pantalla === PANTALLA_TARJETAS? pasoAyuda: null} callbackActulizarPasoActual={this.setPasoAyuda} objetos={onBoardingPasosTarjetas} >
                              <Tarjeta tarjeta={tarjeta} idEquipoModalSeleccionado={this.state.idEquipoDetalleModalSeleccionado} idEquipoSeleccionado={this.state.idEquipoSeleccionado} {...this.props} key={index} />
                            </DimmedAyuda>
                          </DimmedAyuda>
                        </DimmedAyuda>
                  )}                  
                  </div>
                )}
                
              </div> 
            </div>
          </PageModeloDimmed>

        );
    }

   
}