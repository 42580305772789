import _ from "lodash";
import {  useContext, useEffect, useState } from "react";
import {OnBoardingContext} from "../context/OnBoardingContext";


interface PropsPageComparadorDimmed  {
    
    pasoActual?: number | null;
    callbackIniciarPasoAyuda: (pasoAyuda: number | null) => void;

}

const PageComparadorDimmed: React.FunctionComponent<PropsPageComparadorDimmed> = props =>  {
   
    const {onBoarding, setOnBoardingCompletado} = useContext(OnBoardingContext);
    
    useEffect( () => {
        let iniciarPasoAyuda = onBoarding.visit_filtros? 0 : null;
        props.callbackIniciarPasoAyuda(iniciarPasoAyuda);
    },[] )

    useEffect( () => {
        if(onBoarding.visit_filtros === true && props.pasoActual === null){
           
            finalizarOnboarding();
           
        }
    },[props.pasoActual] )
      
    function finalizarOnboarding(){
      let onboarding = _.clone(onBoarding);
      onboarding.visit_filtros = false;
      setOnBoardingCompletado(onboarding);
    }
   

    return (
      <>
        {props.pasoActual !== null && props.pasoActual !== undefined  ?
          <div className={'page-dimmed'}>
            {props.children}
          </div>
        :
          props.children
        }
      </>
    )
  }
  export default PageComparadorDimmed;