import _ from "lodash";
import React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { getFechaFormateadaSinHora } from "../utils/FuncionesFechas";
import BotonColorTarjeta from "./BotonColor";
import { ModalColoresEquipo } from "./ModalColoresEquipo";
import {ModalHistoricoEquipo} from "./ModalHistoricoEquipo";
import { ModalMantenimientoEquipo } from "./ModalMantenimientoEquipo";


interface PropTarjeta extends RouteComponentProps{   
    tarjeta: any;
    idEquipoSeleccionado?: number | null;
    idEquipoModalSeleccionado?: number | null;
}

type StateTarjeta = {
    idEquipoDesplegado: number | null;
    equipoDesplegado: any | null;
    mostrarModalHistoricoEquipo: boolean;

    equipoColores: number | null;
    equipoMantenimiento: any | null;
}

export class Tarjeta extends Component<PropTarjeta, StateTarjeta> {
  
    myRef: React.RefObject<HTMLDivElement>;

    constructor(props:PropTarjeta) {
      super(props)
      this.myRef = React.createRef();  
      this.state={ 
          idEquipoDesplegado: null,
          equipoDesplegado: null,
          mostrarModalHistoricoEquipo: false,
          equipoMantenimiento: null,
          equipoColores: null,
      }
    }

    componentDidMount = () => {
      if(this.props.idEquipoSeleccionado){
        this.seleccionarEquipoPorDefecto();
      }
      if(this.props.idEquipoModalSeleccionado){
        var equipoSeleccionadoPorDefecto = _.find(this.props.tarjeta.equipos, {id: this.props.idEquipoModalSeleccionado});
        this.onDesplegarModalColoresEquipo(equipoSeleccionadoPorDefecto)
      }
    }
    componentDidUpdate(prevProps: PropTarjeta, prevState: StateTarjeta) {
     
      if( !_.isEqual(this.props.idEquipoSeleccionado, prevProps.idEquipoSeleccionado)
        || !_.isEqual(this.props.tarjeta, prevProps.tarjeta)
        ){

        this.seleccionarEquipoPorDefecto();
     
      }

    }

    seleccionarEquipoPorDefecto = () => {
      if(this.props.idEquipoSeleccionado){
        var equipoSeleccionadoPorDefecto = _.find(this.props.tarjeta.equipos, {id: this.props.idEquipoSeleccionado});
          //comprueba que pueda ser clickable y lo selecciona:
        if(equipoSeleccionadoPorDefecto?.latest_equipo && this.tieneDatosCompletosEquipo(equipoSeleccionadoPorDefecto?.latest_equipo,false)){
          this.setState(
            {
              idEquipoDesplegado: this.props.idEquipoSeleccionado,
              equipoDesplegado: equipoSeleccionadoPorDefecto,
            }, () => {
              this.scrollAEquipoPorDefecto()

            }
          )
        }else{
          this.scrollAEquipoPorDefecto()
        }
      }
    }

    scrollAEquipoPorDefecto = () => {
      if(this.myRef.current){

        let headerOffset = 64;
        if(document !== null){
           headerOffset = document.getElementsByClassName('topnav')[0].clientHeight;
        }
        // this.myRef.current.scrollIntoView(true);
        // window.scrollBy(0, -headerOffset);
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = this.myRef.current.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - headerOffset;
        // var elementPosition = this.myRef.current.getBoundingClientRect().top;
        // var offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
  
      }
    }
      

    abrirModalHistoricoEquipo = () => {
      this.setState({
        mostrarModalHistoricoEquipo: true
      })
    }
  
    cerrarModalHistoricoEquipo = () => {
      this.setState({
        mostrarModalHistoricoEquipo: false
      })
    }

    abrirModalMantenimientoEquipo = (equipo: any) => {

      this.setState({
        equipoMantenimiento: equipo.latest_equipo.mantenimiento
        //  equipoMantenimiento: {empresa: {nombre: 'Versia'}}
      },() => {

    }
    )
    }
  
    cerrarModalMantenimientoEquipo = () => {
      this.setState({
        equipoMantenimiento: null
      })
    }

    onDesplegarEquipo = (equipo: any) => {
       if (this.state.idEquipoDesplegado && this.state.idEquipoDesplegado == equipo.id) {
         this.setState({
          idEquipoDesplegado: null,
          equipoDesplegado: null
         })
       } else {
        this.setState({
          idEquipoDesplegado: equipo.id,
          equipoDesplegado: equipo
         })
       }
    }

    estaDesplegado(equipo: any) {
      if (this.state.idEquipoDesplegado && this.state.idEquipoDesplegado == equipo.id) {
          return true;
      } else {
          return false;
      }
  }

  onDesplegarModalColoresEquipo = (equipo: any) => {
     this.setState({
       equipoColores: equipo
      })
  }

  onCerrarModalColoresEquipo = () => {
    this.setState({
      equipoColores: null
    })
  }

  tieneDatosCompletosEquipo(equipo: any, conNombre: boolean) {
    if (conNombre) {
      if (equipo && (equipo.nombre || equipo.descripcion || equipo.fichero_pdf || equipo.url || equipo.fotos.length > 0)) {
        return true;
      } else return false;
    } else {
      if (equipo && (equipo.descripcion || equipo.fichero_pdf || equipo.url || equipo.fotos.length > 0)) {
        return true;
      } else return false;
    }
  }

  tieneDatosCompletosTarjeta(tarjeta: any) {
    if (tarjeta.equipos && tarjeta.equipos.length > 0) {
      let equipoCompleto = false;
      tarjeta.equipos.map((equipo: any) => {
        if (!equipoCompleto && this.tieneDatosCompletosEquipo(equipo.latest_equipo, true)) {
          equipoCompleto = true;
        }
      });
      return equipoCompleto;
    } else return false;
  }

  onComparador(tarjeta: any, nombreTarjeta: any) {
    this.props.history.push("/portada/comparador?tarjeta=" + tarjeta + "&nombre=" + encodeURI(nombreTarjeta), true)
  }
  mostrarVer(equipo: any){
    return equipo.latest_equipo.estado.color !== "verde" || equipo.presupuesto !== null
  }


    render () {
        let tarjeta = this.props.tarjeta;

        return(
          <>
            <div className="main__tarjeta" >
                    <div className={"main__tarjeta__titulo " + (this.tieneDatosCompletosTarjeta(tarjeta) ? "" : "sin-contenido ") + (tarjeta.frio ? "frio " : "")}>
                      {tarjeta.nombre}
                      <button className="btn_comparador" onClick={() => this.onComparador(tarjeta.id, tarjeta.nombre)}><span>Comparar</span></button></div>
                    <div className="main__tarjeta__equipos" key={tarjeta.id}>
                    {tarjeta.equipos && tarjeta.equipos.map((equipo: any) => 
                      this.tieneDatosCompletosEquipo(equipo.latest_equipo, true) && (
                        <>                       
                        <div onClick={() => this.tieneDatosCompletosEquipo(equipo.latest_equipo, false) && this.onDesplegarEquipo(equipo)} className={"main__tarjeta__equipo " + (this.estaDesplegado(equipo) ? "equipo-activo " : "") + (equipo.frio ? "frio " : "")  }
                           ref={(equipo.id === this.props.idEquipoSeleccionado ? this.myRef: undefined)}>
                        
                          <span>{equipo.latest_equipo.nombre}</span>
                          <div className={ (equipo.latest_equipo.mantenimiento !== null ? equipo.latest_equipo.mantenimiento.empresa?.nombre === 'EROSKI' ? 'equipo__mantenimiento mant__interno' : 'equipo__mantenimiento mant__externo' : '' )}
                            onClick={(event: any) =>  {equipo.latest_equipo.mantenimiento !== null && event.stopPropagation(); this.abrirModalMantenimientoEquipo(equipo)}}></div>
                          {/* <div className="equipo__semaforo"><div onClick={(event: any) =>  {event.stopPropagation(); this.onDesplegarModalColoresEquipo(equipo)}} className={`equipo__semaforo__indicador ${equipo.latest_equipo.estado.color}`}></div></div> */}
                          <BotonColorTarjeta mostrarVer={this.mostrarVer(equipo)} onClick={this.mostrarVer(equipo) ? () => this.onDesplegarModalColoresEquipo(equipo) : undefined} color={equipo.latest_equipo.estado.color}></BotonColorTarjeta>
                          <span className={_.join(["flecha-acordeon", !this.tieneDatosCompletosEquipo(equipo.latest_equipo, false) ? "flecha-acordeon__hidden" : ""]," ")}></span>
                        </div>                        
                        <div className="main__tarjeta__equipo__contenido">
                          {equipo.latest_equipo.descripcion && (
                            <div className="descripcion-equipo" dangerouslySetInnerHTML={{__html: equipo.latest_equipo.descripcion}}></div>
                          )}
                          {equipo.latest_equipo.fichero_pdf && (
                            <div className="archivo-adjunto">
                              <a href={equipo.latest_equipo.fichero_pdf} target="_blank">{equipo.latest_equipo.titulo_fichero ? equipo.latest_equipo.titulo_fichero : "Documento adjunto"}</a>
                            </div>
                          )}
                          {equipo.latest_equipo.url && (
                            <div className="link-externo">
                              <a href={equipo.latest_equipo.url} target="_blank">Normativa</a>
                            </div>
                          )}                          
                          {equipo.latest_equipo.fotos && equipo.latest_equipo.fotos.map((foto: any) => 
                            <img src={foto.foto} onLoad={()=> equipo.id === this.props.idEquipoSeleccionado && this.scrollAEquipoPorDefecto()} />
                          )}
                          {equipo.latest_equipo.udpate_date && (
                            <span className="ult-act">
                              Últ. actualización: 
                              <a onClick={this.abrirModalHistoricoEquipo}> {getFechaFormateadaSinHora(new Date(equipo.latest_equipo.udpate_date))}</a>
                            </span>
                          )}
                        </div>
                        </>
                      )                   
                      
                    )}                     
                    </div>                   
            </div>
            {this.state.mostrarModalHistoricoEquipo && (
              <ModalHistoricoEquipo equipo={this.state.equipoDesplegado} onCerrarModal={this.cerrarModalHistoricoEquipo} />
            )}
            {this.state.equipoColores && (
              <ModalColoresEquipo equipo={this.state.equipoColores} onCerrarModal={this.onCerrarModalColoresEquipo} />
            )}
            {this.state.equipoMantenimiento !== null && (
              <ModalMantenimientoEquipo mantenimiento={this.state.equipoMantenimiento} onCerrarModal={this.cerrarModalMantenimientoEquipo} />
            )}
            </>
        )
    }
 }

