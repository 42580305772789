import {  useEffect, useState, useContext } from "react";

import { RouteComponentProps, useHistory } from "react-router";
import axios, { AxiosResponse } from "axios";
import L, { LatLngExpression } from "leaflet";
import { MapContainer , Marker, TileLayer, Tooltip} from "react-leaflet";
import _ from "lodash";
import "leaflet/dist/leaflet.css";
import { Buscador } from "../componentes/Buscador";
import {ESTADO_ONBOARDING_COMPLETADO, ESTADO_ONBOARDING_VISITAR, OnBoardingContext} from "../context/OnBoardingContext";
import DimmedAyuda from "../componentes/DimmedAyuda";
import { onBoardingPasosListadoPlataforma } from "../constantes/onBoarding";

const iconoFresco = new L.DivIcon({
  className: 'mapa-indicador-azul'
});

const iconoSeco = new L.DivIcon({
  className: 'mapa-indicador-naranja'
});

const iconoGris = new L.DivIcon({
  className: 'mapa-indicador-gris'
});

interface PropPlataformas extends RouteComponentProps {

}

// type StatePlataformas = {
//   plataformas: any[] | [];
//   plataformasSinProvincia: any[] | [];
//   provincias: any[] | [];
//   plataformasBuscadas: any[] | [];
//   textoBusqueda: string;
// }

export const Plataformas = (props: PropPlataformas)=> { 

    const {onBoarding, setOnBoardingCompletado, setOnBoarding} = useContext(OnBoardingContext);

    const [plataformas, setplataformas] = useState<any[] | []>([]);
    const [plataformasSinProvincia, setplataformasSinProvincia] = useState<any[] | []>([]);
    const [provincias, setprovincias] = useState<any[] | []>([]);
    const [plataformasBuscadas, setplataformasBuscadas] = useState<any[] | []>([]);
    const [textoBusqueda, settextoBusqueda] = useState<string>("");
    const [pasoAyuda, setPasoAyuda] = useState<number | null>(onBoarding.visit_lista_plataformas === ESTADO_ONBOARDING_COMPLETADO ? null : 0);
    
    const history = useHistory();

    useEffect( () => {
      recuperarPlataformas();
    },[] )

    useEffect( () => {
        if(onBoarding.visit_lista_plataformas === ESTADO_ONBOARDING_COMPLETADO){
          setPasoAyuda(null)
        }else{
          setPasoAyuda(0)
        }
       
    }, [onBoarding]);

    useEffect( () => {
    if(onBoarding.visit_lista_plataformas !== ESTADO_ONBOARDING_COMPLETADO && pasoAyuda === null){
        finalizarOnboarding();
      }
    }, [pasoAyuda]);

    const finalizarOnboarding = () => {
        let onboarding = _.clone(onBoarding);
        onboarding.visit_lista_plataformas = false;
        if(onBoarding.visit_lista_plataformas === ESTADO_ONBOARDING_VISITAR){
            setOnBoardingCompletado(onboarding);
        }else{
            setOnBoarding(onboarding);
        }
    }

    function recuperarPlataformas() {
        axios.get('/lista-plataforma/').then((response: AxiosResponse) => {
        let provincias: any[] = [];
        let plataformasSinProvincia: any[] = [];
        response.data.map((plataforma: any) => {
            if (plataforma.provincia) {
                if (!_.find(provincias, {id: plataforma.provincia.id})) {
                provincias.push(plataforma.provincia);
                }
            } else {
            plataformasSinProvincia.push(plataforma)
            }
        })
        setplataformas(response.data);
        setplataformasSinProvincia(plataformasSinProvincia);
        setprovincias(provincias);
        })
    }

    const onVolver = () => {
        history.goBack();
    }

    const onVolverPortada = () => {
        history.push("/portada")
    }

    function getIconoMapa(tipo: any) {
        if (tipo) {
        if (tipo.nombre == "Fresco") {
            return iconoFresco;
        } else if (tipo.nombre == "Seco") {
            return iconoSeco;
        } else {
            return iconoGris;
        }
        } else return iconoGris;
    }

    const onChangeTexto = (event: any) =>{
        let texto = event.target.value;

        if (texto.length > 2) {
        
        let plataformasFiltradas = _.filter(_.clone(plataformas), function (plataforma: any) {
            return plataforma.nombre.toLowerCase().indexOf(texto.toLowerCase()) > -1 || (plataforma.provincia && plataforma.provincia.nombre.toLowerCase().indexOf(texto.toLowerCase()) > -1);
        })
        settextoBusqueda(texto);
        setplataformasBuscadas(plataformasFiltradas)
        
        } else if (texto.length == 0) {
            settextoBusqueda("");
            setplataformasBuscadas([])
        }
        
    }



        const position : LatLngExpression = [41.6480626, -0.9834766];
        const zoom : number = 7;

        return (
            <div className={"libro"  + (pasoAyuda !== null ? " page-dimmed" : "")}>
                
                <div className="topnav">
                <div className="topnav__nav"> 
                    <button className="btn-volver" onClick={onVolver}></button>
                    <div className="breadcrumb">
                    <a className="breadcrumb__portada" onClick={onVolverPortada}>Portada</a>
                    /
                    <a className="breadcrumb__activo">Plataformas</a>
                    </div>
                </div>
                <div className="topnav__buscador">
                    <Buscador {...props} />
                </div>
                <div className="topnav-logo-eroski"></div>
                </div>

                    <div className="sidenav sidenav__plataformas">
                <DimmedAyuda  paso={0} pasoActual={pasoAyuda} orientation_mobile="top" callbackActulizarPasoActual={setPasoAyuda} objetos={onBoardingPasosListadoPlataforma} >
                        {/* <button className="btn-cerrar"></button> */}
                        {/* <div className="sidenav__selector"> */}
                        <div className="sidenav__plataformas__buscar">
                        <label>Filtrar</label>
                        <input type="search" placeholder="Provincia o plataforma" onChange={onChangeTexto}></input>
                        </div>
                        <div className="sidenav__plataformas__menu">
                        {textoBusqueda.length > 2 ? (
                            <div className="sidenav__plataformas__comunidad comunidad-activa">
                            <div className="sidenav__plataformas__comunidad__botones">
                            {plataformasBuscadas.map((plataforma: any) =>                       
                            <button style={{backgroundImage: plataforma.foto_min ? 'url(' + plataforma.foto_min + ')' : ""}} type="button" onClick={() => history.push("/portada/modelo/" + plataforma.id)}><span>{plataforma.nombre}</span></button>      
                            )}
                            </div>
                            </div>
                        ) : (
                            <>
                            {provincias.map((provincia: any) => 
                            <div className="sidenav__plataformas__comunidad comunidad-activa">
                                <div className="sidenav__plataformas__comunidad__titulo">
                                {provincia.nombre}
                                </div>
                                <div className="sidenav__plataformas__comunidad__botones">
                                {plataformas.map((plataforma: any) => plataforma.provincia && plataforma.provincia.id == provincia.id &&                                  
                                <button style={{backgroundImage: plataforma.foto_min ? 'url(' + plataforma.foto_min + ')' : ""}} type="button" onClick={() => history.push("/portada/modelo/" + plataforma.id)}><span>{plataforma.nombre}</span></button>      
                                )}
                                </div>                  
                            </div>
                            )}
                            {plataformasSinProvincia.length > 0 && (
                            <div className="sidenav__plataformas__comunidad comunidad-activa">
                            <div className="sidenav__plataformas__comunidad__titulo">
                                Otras
                            </div>
                            <div className="sidenav__plataformas__comunidad__botones">
                                {plataformasSinProvincia.map((plataforma: any) => !plataforma.provincia &&                      
                                <button style={{backgroundImage: plataforma.foto_min ? 'url(' + plataforma.foto_min + ')' : ""}} type="button" onClick={() => history.push("/portada/modelo/" + plataforma.id)}><span>{plataforma.nombre}</span></button>      
                                )}
                            </div>
                            </div>
                            )}
                            </>
                        )}
                        
                        </div>
                </DimmedAyuda>
                    </div>
                <div className="main main__plataformas">
                <MapContainer center={position} zoom={zoom} scrollWheelZoom={true}>
                    <TileLayer
                    attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {textoBusqueda.length > 2 ? (
                    <>
                    {plataformasBuscadas.map((plataforma: any) => plataforma.lat && plataforma.lon &&                                  
                        <Marker key={plataforma.id} position={[plataforma.lat, plataforma.lon]} title={plataforma.nombre} icon={getIconoMapa(plataforma.tipo)}
                        eventHandlers={{
                        click: (e) => {
                            history.push("/portada/modelo/" + plataforma.id)
                        },
                        }}>
                        <Tooltip direction='right' offset={[10, 0]} opacity={0.8} permanent>
                            <span>{plataforma.nombre}</span>
                        </Tooltip>
                        </Marker>     
                    )}
                    </>
                    ) : (
                    <>
                    {plataformas.map((plataforma: any) => plataforma.lat && plataforma.lon &&                                  
                        <Marker key={plataforma.id} position={[plataforma.lat, plataforma.lon]} title={plataforma.nombre} icon={getIconoMapa(plataforma.tipo)}
                        eventHandlers={{
                        click: (e) => {
                            history.push("/portada/modelo/" + plataforma.id)
                        },
                        }}>
                        <Tooltip direction='right' offset={[10, 0]} opacity={0.8} permanent>
                            <span>{plataforma.nombre}</span>
                        </Tooltip>
                        </Marker>     
                    )}
                    </>
                    )}
                    
                </MapContainer>
                
                </div> {/* fin .main */}
            </div>
        );
        

   
}