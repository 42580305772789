import { AxiosResponse } from "axios";
import React, { useContext, useEffect, useState } from "react";
import {isRoleAdminEroski, RoleContext} from "../context/RoleContext";
import { listaDocumentacion } from "../service/ListaDocumentacionAPI";


type Enlace = {
    nombre: string;
    pdf: string;
  }

const BotonListaDocumentacion = () => {

    let enlaceDocumento: Enlace | null = null;
    const [ mostrarBoton, setMostrarBoton] = useState<boolean>(false);
    const {groups} = useContext(RoleContext);
    
    useEffect( () => {
        if(isRoleAdminEroski(groups)){
            setMostrarBoton(true);
        }else{
            setMostrarBoton(false);
        }
    }, [groups]);


    function recuperarListaDocumentacion() {
    
        listaDocumentacion().then((response: AxiosResponse) => {
          
            if(response.data && response.data.length > 0){
                //seteamos el documentos y lo abrimos:
                enlaceDocumento = response.data[0];
                abrirDocumento();
            }else{
                //no tiene datos: no muestre boton
                setMostrarBoton(false);
            }
        })
    
    }

    function abrirDocumento(){
        //abre el link en otra pestaña
        if(enlaceDocumento !== null){
            const link = document.createElement('a');
            link.href = enlaceDocumento.pdf;
            link.setAttribute('download', enlaceDocumento?.nombre); //or any other extension
            link.setAttribute('target', '_blank'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }
    }

    function handleClick(){

        if(enlaceDocumento === null){
            recuperarListaDocumentacion();
        }else{
            abrirDocumento();
        }
    }


    return (
        <>
            {mostrarBoton &&
                <button className="btn-ayuda btn-documentacion" onClick={handleClick}><span></span>Buenos usos</button>
            }
        </>
    )
}
export default BotonListaDocumentacion;