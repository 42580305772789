import _ from "lodash";
import React from "react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {ESTADO_ONBOARDING_REPETIR, OnBoardingContext} from "../context/OnBoardingContext";
import { URL_MODELO } from "../pantallas/Portada";
import fotoPortada1 from '../img/modal-ayuda/may-portada1.png';
import fotoModelo1 from '../img/modal-ayuda/may-modelo1.png';
import fotoModelo2 from '../img/modal-ayuda/may-modelo2.png';
import fotoModelo3 from '../img/modal-ayuda/may-modelo3.png';
import fotoModelo4 from '../img/modal-ayuda/may-modelo4.png';
import fotoModelo5 from '../img/modal-ayuda/may-modelo5.png';
import fotoInversionesFuturas from '../img/modal-ayuda/may-inversiones-futuras.png';
import fotoPlataformas1 from '../img/modal-ayuda/may-plataformas1.png';
 import fotoPlataformas2 from '../img/modal-ayuda/may-plataformas2.png';

import fotoPlataformas3 from '../img/modal-ayuda/may-plataformas3.png';
import fotoPlataformas4 from '../img/modal-ayuda/may-plataformas4.png';
import fotoPlataformas5 from '../img/modal-ayuda/may-plataformas5.png';
import fotoPlataformas6 from '../img/modal-ayuda/may-plataformas6.png';
import fotoPlataformas7 from '../img/modal-ayuda/may-plataformas7.png';

import fotomantenimientoEroski from '../img/mant-eroski.svg';
import fotomantenimientoExt from '../img/mant-ext.svg';
import fotocomparador from '../img/btn-comp1.svg';
import fotofrio from '../img/icono-frio-blanco.svg';



interface PropModalAyuda  {   
    onCerrarModal: () => void;
}



export const ModalAyuda = (props:PropModalAyuda) => {
  
    const [sectionVisible, setSectionVisible] = useState<string | null>(null);
    
    const {setOnBoarding, onBoarding } = useContext(OnBoardingContext);
    const history = useHistory();

    const refModelo = React.createRef<HTMLDivElement>();
    const refPlataformas = React.createRef<HTMLDivElement>();
    const refInversiones = React.createRef<HTMLDivElement>();


    useEffect( () => {
        if(sectionVisible !== null){
            if(sectionVisible === "modelo" ){
            history.push(URL_MODELO);
            }else if(sectionVisible === "portada"){
            history.push("/portada");
            }else if(sectionVisible === "plataformas"){
            history.push("/portada/modelo/2");
            }else if(sectionVisible === "inversiones-futuras"){
            history.push("/portada");
            }else{
            history.push("/portada");
            }
            // //cerramos modal
            props.onCerrarModal();
        }
    }, [onBoarding])
    
  
    const onCerrarModal = () => {
      props.onCerrarModal()
    }


    const onboardingClick = (id: string) => {
        let onboarding = _.clone(onBoarding);
        
        if(id === "modelo" ){
          onboarding.visit_tarjetas = ESTADO_ONBOARDING_REPETIR;
        }else if(id === "portada"){
          onboarding.visit_portada = ESTADO_ONBOARDING_REPETIR;
        }else if(id === "plataformas"){
          onboarding.visit_detalle_plataforma = ESTADO_ONBOARDING_REPETIR;
        }else if(id === "inversiones-futuras"){
          onboarding.visit_inversiones_futuras = ESTADO_ONBOARDING_REPETIR;
        }else{
          onboarding.visit_portada = ESTADO_ONBOARDING_REPETIR;
        }

        setOnBoarding(onboarding);
        setSectionVisible(id);

    }

    const scrollTo = (ref: React.RefObject<HTMLDivElement>) => {
        ref.current?.scrollIntoView({block: 'start', behavior: 'smooth'})
    }


        return(
            <div className="modal-ayuda">
                <div className="cerrar-modal" onClick={onCerrarModal}></div>
                <div className="modal-ayuda__equipo">
                    <>
                        <div className="modal-ayuda__equipo__titulo ">
                            <span>Ayuda</span>
                        {/* <div className={"abrir" + ( estaDesplegado(1) ? " abierto" : "") } onClick={() => onDesplegar(1)}></div> */}
                        </div>
                        
                        <div className="modal-ayuda__equipo__version__contenido" >
                            <div>
                                <div id="portada">
                                    <Titulo titulo={''} callbackOnboarding={() => onboardingClick('portada')}></Titulo>
                                    <div>
                                        <div className="foto-portada1"><img src={fotoPortada1} alt="Portada"></img></div>
                                        <div>
                                            <div className="main__tarjetas">
                                                <Tarjeta titulo="Modelo" descripcion="Muestra una plataforma prototipo." callbackOnClick={() => scrollTo(refModelo)}></Tarjeta>
                                                <Tarjeta titulo="Plataformas" descripcion="Muestra todas las plataformas." callbackOnClick={() => scrollTo(refPlataformas)}></Tarjeta>
                                                <Tarjeta titulo="Inversiones futuras" descripcion="Muestra las inversiones por plataforma." callbackOnClick={() => scrollTo(refInversiones)}></Tarjeta>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div id="modelo" ref={refModelo}>
                                    <Titulo titulo={'Modelo'} callbackOnboarding={() => onboardingClick('modelo')}></Titulo>
                                    <ContenidoModelo></ContenidoModelo>
                                </div>
                                <div id="plataformas" ref={refPlataformas}>
                                    <Titulo titulo={'Plataformas'} callbackOnboarding={() => onboardingClick('plataformas')}></Titulo>
                                    <ContenidoPlataformas></ContenidoPlataformas>
                                </div>
                                <div id="inversiones-futuras" ref={refInversiones}>
                                    <Titulo titulo={'Inversiones futuras'} callbackOnboarding={() => onboardingClick('inversiones-futuras')}></Titulo>
                                    <ContenidoInversionesFuturas></ContenidoInversionesFuturas>
                                </div>
                            </div>
                        </div>
                        
                    </>
                </div>
            </div>
        )
 }

type PropsTarjeta = {
    titulo: string
    descripcion: string
    callbackOnClick: () => void;
}

const Tarjeta = (props: PropsTarjeta) => {
    return (
        <div className="main__tarjeta">
            <div className="main__tarjeta__titulo ">{props.titulo}</div>
            <div className="main__tarjeta__descripcion">
                <div><span>{props.descripcion}</span></div>
                <button className="main__tarjeta__btn-tarjeta" onClick={props.callbackOnClick}></button>
            </div>
        </div>
    )
}
type PropsTitulo = {
    titulo: string
    callbackOnboarding: () => void

}

const Titulo = (props: PropsTitulo) => {
    return (
        <div className="modal-ayuda__seccion-titulo"> 
            {props.titulo !== '' &&
             <h2>{props.titulo}</h2>
            }
            <button className="btn-onboarding" onClick={props.callbackOnboarding}>On boarding</button>
        </div>

    )
}
const ContenidoModelo = () => {
    return(
        <>
            <div className="item-modelo">
                <h3>Organización de plataformas</h3>
                <div>
                    <img src={fotoModelo1} alt="Organización de plataformas"></img>
                    <div>
                        <ol>
                            <li>SECCIÓN</li>
                            <li>SUBSECCIÓN</li>
                            <li>TARJETA</li>
                            <li>EQUIPO</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="item-modelo">
                <h3>Migas de pan</h3>
                <div>
                    <img src={fotoModelo2} alt="Migas de pan"></img>
                    <div>
                        <p>Indica donde se encuentra el usuario dentro la aplicación y los pasos seguidos para llegar allí.</p>
                    </div>
                </div>
            </div>
            <div className="item-modelo">
                <h3>Barra de búsqueda</h3>
                <div>
                    <img src={fotoModelo3} alt="Barra de búsqueda"></img>
                    <div>
                        <p>
                            Los resultados se ordenan por importancia. 
                        </p>
                        <p>
                            Clickando en "Ver todos los resultados", llegamos al buscador avanzado.
                        </p>
                    </div>
                </div>
            </div>
            <div className="item-modelo">
                <h3>Búsqueda avanzada</h3>
                <div>
                    <img src={fotoModelo4} alt="Búsqueda avanzada"></img>
                    <div>
                        <p>
                            Opción de filtrar los resultados y acceder a ellos pulsando el botón VER. 
                        </p>
                        <p>
                            En la parte superior del nombre se muestra la ubicación del objeto.
                        </p>
                    </div>
                </div>
            </div>
            <div className="item-modelo equipo">
                <h3>Equipos</h3>
                <div>
                    <img src={fotoModelo5} alt="Equipos"></img>
                    <div>
                        <p>
                            Clickando una tarjeta se muestra una fotografía, un archivo descargable y la última actualización.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
const ContenidoPlataformas = () => {
    return(
        <>
            <div className="item-plataformas organizacion">
                <h3>Organización de plataformas</h3>
                <div>
                    <div className="paso1">
                        <ol>
                            <li>
                                INVERSIONES FUTURAS
                                <p>Se obtiene información acerca de las inversiones y del estado de la plataforma.</p> 
                            </li>
                        </ol>
                    </div>
                    <img src={fotoPlataformas1} alt="Organización de plataformas"></img>
                    
                    <div className="paso2">
                        <ol start={2}>
                            
                            <li>
                                INFORMACIÓN GENERAL
                                <p>acerca de la plataforma.</p> 
                            </li>
                            <li>
                                BOTÓN PARA DESCARGAR
                                <p>toda la información disponible en cada plataforma.</p> 
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="item-plataformas info-equipo">
                <h3>Información de un equipo</h3>
                <div>
                    <img className="img_60" src={fotoPlataformas2} alt="Información de un equipo"></img>
                    <div>
                        <ol>
                            <li>COMPARAR</li>
                            <li>CONSERVACIÓN DE FRÍO</li>
                            <li>MANTENIMIENTO</li>
                            <li>ESTADO DEL EQUIPO</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="subitem-plataformas">
                <h4>1. BOTÓN COMPARAR<img alt="Icono comparador" className="icono-comparador" src={fotocomparador}></img></h4>
                <span>Compara las tarjetas entre si usando un comparador.</span>
                <div>
                    <img className="img_50" src={fotoPlataformas3}  alt="Botón comparar"></img>
                    <div>
                        <ol>
                            <li>Opción de filtrar los resultados.</li>
                            <li>Migas de pan para saber donde está ubicado.</li>
                            <li>Opción "VER" la información.</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="subitem-plataformas">
                <h4>2. CONSERVACIÓN DEL FRÍO<img alt="Icono frío" className="icono-frio" src={fotofrio}></img></h4>
                <span>Indica que esta tarjeta tiene relación con la generación o conservación de frío.</span>
                <div>
                    <img src={fotoPlataformas4} alt="Botón comparar"></img>
                </div>
            </div>
            <div className="subitem-plataformas">
                <h4>3. BOTÓN DE MANTENIMIENTO<img alt="Icono mantenimiento" className="icono-manten"  src={fotomantenimientoExt}></img></h4>
                <span>Ofrece información sobre el mantenedor del equipo. Puede ser:</span>
                <div>
                    <img src={fotoPlataformas5} alt="Botón comparar"></img>
                    <ul className="listado-mantenimiento">
                        <li><img className="icono-manten" alt="Icono mantenimiento" src={fotomantenimientoExt}></img>Externo</li>
                        <li><img className="icono-manten" alt="Icono mantenimiento eroski" src={fotomantenimientoEroski}></img>Interno</li>
                    </ul>
                </div>
            </div>
            <div className="subitem-plataformas">
                
                <h4>4. ESTADO DEL EQUIPO<div className="boton-ver__equipo__semaforo"><span>Ver</span><div className="equipo__semaforo"><div className="equipo__semaforo__indicador amarillo"></div></div></div></h4>
                <span>Indica el estado del Equipo y el presupuesto asociado.</span>
                <div>
                    <img src={fotoPlataformas6} alt="Botón comparar"></img>
                    <div className="listado-semaforos">
                        <div>
                            <span className="ficha__semaforo verde"></span>
                            <span>Buen estado</span>
                        </div>
                        <div>
                            <span className="ficha__semaforo amarillo"></span>
                            <span>Intervención a medio plazo</span>
                        </div>
                        <div>
                            <span className="ficha__semaforo rojo"></span>
                            <span>Intervención inmediata/corto plazo</span>
                        </div>
                        </div>
                </div>
            </div>
            <div className="subitem-plataformas">
                <h4>5. NOMBRE DEL EQUIPO</h4>
                <span>Clickando en el nombre del equipo se muestra la foto del equipo, el estado actual y la última actualización.</span>
                <div>
                    <img src={fotoPlataformas7} alt="Botón comparar"></img>
                </div>
            </div>
            </>
    )
}
const ContenidoInversionesFuturas = () => {
    return(
        <>
            <div className="item-inversiones">
                <h3>Plataformas Y Presupuesto</h3>
                <p>Proporciona información sobre las inversiones de cada una de las plataformas y sus equipos.</p>
                <img src={fotoInversionesFuturas} alt="Inversiones futuras"></img>
            </div>
        </>
    )
}