import axios, { AxiosResponse } from "axios";
import _ from "lodash";
import { Component } from "react";
import { getFechaFormateadaSinHora } from "../utils/FuncionesFechas";
import { getNumeroFormateado } from "../utils/FuncionesNumeros";

interface PropsColoresEquipo  {   
    equipo: any;
    onCerrarModal: () => void;
}

type StateColoresEquipo = {
    
}

export class ModalColoresEquipo extends Component<PropsColoresEquipo, StateColoresEquipo> {


    onCerrarModal = () => {
      this.props.onCerrarModal()
    }


    render () {
      let equipo = this.props.equipo;

      return(
        <div className="modal-historico" style={{display: 'block'}}>
          <div className="historial__equipo">
                  <div className={"historial__equipo__titulo"}>
                    <span>Inversiones futuras: {equipo.latest_equipo.nombre}</span>
                    <div className="cerrar" onClick={this.onCerrarModal}></div>
                  </div>
                  <div className="historial__equipo__inversiones" key={equipo.id}>
                      <ContenidoModalColoresEquipo equipo={equipo}></ContenidoModalColoresEquipo>                                  
                  </div>                   
          </div>
          </div>
      )
  }
 }

 type PropsContenidoModalColoresEquipo = {
  equipo: any;
 }

export const ContenidoModalColoresEquipo = (props: PropsContenidoModalColoresEquipo) => {
  let equipo = props.equipo;
  
  return (
    <>
        <table className="ficha__tabla">
            <tbody>
              <tr>
                  <th>Vida útil</th>
                  <td style={{textAlign: 'right'}}><span className={`ficha__semaforo ${equipo.latest_equipo.vida_util.color}`}></span></td>
              </tr>
              <tr>
                  <th>Eficiencia energética</th>
                  <td style={{textAlign: 'right'}}><span className={`ficha__semaforo ${equipo.latest_equipo.eficiencia_energetica.color}`}></span></td>
              </tr>
              <tr>
                  <th>Normativa</th>
                  <td style={{textAlign: 'right'}}><span className={`ficha__semaforo ${equipo.latest_equipo.normativa.color}`}></span></td>
              </tr>
              <tr>
                  <th>Imprevisto</th>
                  <td style={{textAlign: 'right'}}><span className={`ficha__semaforo ${equipo.latest_equipo.imprevisto.color}`}></span></td>
              </tr>
            
            </tbody>
        </table>
        {equipo.presupuesto && (
          <table className="ficha__tabla__presupuesto">
            <tbody>
              <tr>
                  <th>Presupuesto</th>
                  <td style={{textAlign: 'right'}}>{getNumeroFormateado(equipo.presupuesto)}€</td>
              </tr>                  
            </tbody>
        </table>
        )}   
    </>
  )
 }
