import React from "react";
import _ from "lodash";


interface PropMantenimientoEquipo  {   
    mantenimiento: any | null;
    onCerrarModal: () => void;
}



type Mantenimiento = {
    empresa: { nombre: string, mail: string, web: string}
    nombre: string,
    apellido: string,
    telefono: string,
    mail: string,
    contrato: string
}

export const ModalMantenimientoEquipo = (props: PropMantenimientoEquipo) => {
    
    const onCerrarModal = () => {
        props.onCerrarModal()
    }

    function getEmpresa(empresa: Mantenimiento["empresa"]){
        let filas = [];
        if (empresa.nombre || empresa.mail || empresa.web) {
            if (empresa.nombre) {
                filas.push(empresa.nombre);
            }
            if (empresa.mail) {
                filas.push(<><br></br>{empresa.mail}</>);
            }
            if (empresa.web) {
                filas.push(<><br></br>{empresa.web}</>);
            }
            return filas;
        } else return "-"
    }
    function getContrato(contrato: Mantenimiento["contrato"]){
        let filas = [];
        if (contrato) {
            let enlace = <a href={contrato} target="_blank">Ver contrato</a>
            filas.push(enlace);
            return filas;
        
        } else return "-";
    }
    let mantenimiento:Mantenimiento | null = props.mantenimiento;

    return(
        <div className="modal-mantenimiento">
            <div className="modal-mantenimiento__equipo">
                <div className="modal-mantenimiento__equipo__titulo ">
                    <span>Mantenimiento</span>
                    <div className="cerrar" onClick={onCerrarModal}></div>
                </div>
            {mantenimiento !== null &&
                <>
                <div className="modal-mantenimiento__equipo__version__contenido">
                    
                        <table className="ficha__tabla">
                            <tbody>
                               
                                <tr> 
                                    <th>Empresa</th>
                                    <td>{getEmpresa(mantenimiento.empresa)}</td>
                                </tr>
                                <tr> 
                                    <th>Nombre</th>
                                    <td>{_.defaultTo(mantenimiento.nombre,'-')}</td>
                                </tr>
                                <tr> 
                                    <th>Apellido</th>
                                    <td>{_.defaultTo(mantenimiento.apellido,'-')}</td>
                                </tr>
                                <tr> 
                                    <th>Telefono</th>
                                    <td>{_.defaultTo(mantenimiento.telefono,'-')}</td>
                                </tr>
                                <tr> 
                                    <th>Mail</th>
                                    <td>{_.defaultTo(mantenimiento.mail,'-')}</td>
                                </tr>
                                <tr> 
                                    <th>Contrato</th>
                                    <td>{getContrato(mantenimiento.contrato)}</td>
                                </tr>
                            </tbody>
                        </table>
                    
                </div>
            
            </>
            }
            </div>
        </div>
    )
        
    }
