import React, { useContext, useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { getNumeroFormateado } from "../utils/FuncionesNumeros";
import { ContenidoModalColoresEquipo } from "./ModalColoresEquipo";
import {ESTADO_ONBOARDING_COMPLETADO, ESTADO_ONBOARDING_VISITAR, OnBoardingContext} from "../context/OnBoardingContext";
import DimmedAyuda from "./DimmedAyuda";
import { onBoardingPasosInversionesFuturas } from "../constantes/onBoarding";

interface PropHistoricoInversiones  {   
    // equipo: any;
    onCerrarModal: () => void;
}


type Inversion = {
  nombre: string;
  presupuesto: number | null;
  id: number;
}
type Equipo = {
  id: number;
  nombre: string;
  presupuesto: number;
  sistema: {id: number, nombre: string}
  subsistema: {id: number, nombre: string}
  tarjeta: {id: number, nombre: string}
}

export const ModalHistoricoInversiones = (props: PropHistoricoInversiones) => {

    const {onBoarding, setOnBoardingCompletado, setOnBoarding} = useContext(OnBoardingContext);

    let history = useHistory();
    const [idPlataformaSeleccionada, setIdPlataformaSeleccionada] = useState<number | null>(null);
    const [equipoSeleccionado, setEquipoSeleccionado] = useState<Equipo | null>(null);
    
    //info de api
    const [inversiones, setInversiones] = useState<Inversion[] | null>(null);
    const [equipos, setEquipos] = useState<Equipo[] | null>(null);
    const [infoEquipo, setInfoEquipo] = useState<any | null>(null);
    const [pasoAyuda, setPasoAyuda] = useState<number | null>(onBoarding.visit_inversiones_futuras === ESTADO_ONBOARDING_COMPLETADO ? null : 0);

    useEffect( () => {
        recuperarHistoricoInversiones();
    },[])

    useEffect( () => {
        recuperarEquiposInversiones();
    },[idPlataformaSeleccionada]);

    useEffect( () => {
        recuperarInfoEquipoInversiones();
    },[equipoSeleccionado]);

    useEffect( () => {
        if(onBoarding.visit_inversiones_futuras === ESTADO_ONBOARDING_COMPLETADO){
          setPasoAyuda(null)
        }else{
          setPasoAyuda(0)
        }
        
       
      }, [onBoarding]);

    useEffect( () => {
        if(onBoarding.visit_inversiones_futuras !== ESTADO_ONBOARDING_COMPLETADO){
            if( pasoAyuda === null){
                finalizarOnboarding();
                volverListadoInversionesPlataforma();
            }else if(pasoAyuda === 0){
                volverListadoInversionesPlataforma();
            }else if (pasoAyuda === 1){
                //seleccionamos la primera plataforma
                let firstInversiones = _.first(inversiones);
                volverListadoEquipos();
                if(firstInversiones){
                    goToDetallePlataforma(firstInversiones.id);
                }else{
                    //qué se haría si no hay inversiones
                }
            }else if (pasoAyuda === 2){
                //seleccionamos el primer equipo
                let firstEquipos = _.first(equipos);
                if(firstEquipos){
                    goToDetalleEquipoModal(firstEquipos);
                }else{
                    //qué se haría si no hay equipos
                }
            }
        }
    }, [pasoAyuda]);

    const finalizarOnboarding = () => {
        let onboarding = _.clone(onBoarding);
        onboarding.visit_inversiones_futuras = false;
        if(onBoarding.visit_inversiones_futuras === ESTADO_ONBOARDING_VISITAR){
            setOnBoardingCompletado(onboarding);
          }else{
            setOnBoarding(onboarding);
          }
    }

    const onCerrarModal = () => {
        props.onCerrarModal()
    }

    const recuperarHistoricoInversiones = () => {
        axios.get('/inversiones-futuras').then((response: AxiosResponse) => {
            setInversiones(response.data);
        })
    }

    const recuperarEquiposInversiones = () => {
        if(idPlataformaSeleccionada !== null){
            axios.get('/inversiones-futuras-plataforma/'+ idPlataformaSeleccionada).then((response: AxiosResponse) => {
                setEquipos(response.data);
            })
        }
    }
    const recuperarInfoEquipoInversiones = () => {

        if(equipoSeleccionado !== null && idPlataformaSeleccionada) {
   
            axios.get('/lista-equipo/?plataforma='+idPlataformaSeleccionada +'&tarjeta='+ equipoSeleccionado?.tarjeta.id+'&equipo='+equipoSeleccionado.id).then((response: AxiosResponse) => {
            let infoEquipo = _.find(response.data, {id: equipoSeleccionado.id});
                setInfoEquipo(infoEquipo);
            })
        }
        
    }

    const goToDetallePlataforma = (idPlataforma: number) => {
        if(idPlataformaSeleccionada !== null){
            axios.get('/inversiones-futuras-plataforma/'+ idPlataformaSeleccionada).then((response: AxiosResponse) => {
                setEquipos(response.data);
            })
        }
        setIdPlataformaSeleccionada(idPlataforma);
    }

    const goToDetalleEquipoModal = (equipo: Equipo) => {
        setEquipoSeleccionado(equipo)
        // history.push("/portada/modelo/"+ idPlataformaSeleccionada + "?sistema="+ idSistema +"&subsistema="+ idSubsistema +"&modal-equipo="+ idEquipo);
    }

    const volverListadoInversionesPlataforma = () => {
        setIdPlataformaSeleccionada(null);
        setEquipoSeleccionado(null);
        setInfoEquipo(null);
    }
    const volverListadoEquipos = () => {
        setEquipoSeleccionado(null);
        setInfoEquipo(null);
        // setEquipos(null);
    }


    let plataformaSeleccionada:Inversion | undefined = inversiones !== null && idPlataformaSeleccionada ? _.find(inversiones, {id: idPlataformaSeleccionada}) : undefined;

    return(
        <div className={"modal-inversiones-portada" + (pasoAyuda !== null ? " page-dimmed" : "")}>

            <DimmedAyuda  paso={0} orientation_mobile="bottom" orientation_tablet="bottom" pasoActual={pasoAyuda} callbackActulizarPasoActual={setPasoAyuda} objetos={onBoardingPasosInversionesFuturas} >
            <DimmedAyuda  paso={1} orientation_mobile="bottom" orientation_tablet="bottom" pasoActual={pasoAyuda} callbackActulizarPasoActual={setPasoAyuda} objetos={onBoardingPasosInversionesFuturas} >
            <DimmedAyuda  paso={2} orientation_mobile="bottom" orientation_tablet="bottom" pasoActual={pasoAyuda} callbackActulizarPasoActual={setPasoAyuda} objetos={onBoardingPasosInversionesFuturas} >
                <div className="modal-inversiones-portada__equipo">
                
                    <div className="modal-inversiones-portada__equipo__titulo ">
                        <span>Inversiones futuras
                            {/* {plataformaSeleccionada !== undefined && equipoSeleccionado === null && (': ' + plataformaSeleccionada.nombre  )}{plataformaSeleccionada !== undefined && equipoSeleccionado !== null && (': '+ equipoSeleccionado.nombre)} */}
                            </span>
                        <div className="cerrar" onClick={onCerrarModal}></div>
                    </div>
                    {inversiones !== null &&
                        <>
                        <div className={"modal-inversiones-portada__equipo__version__contenido" + (equipoSeleccionado !== null ? ' modal-inversiones-portada__equipo_historico_info' : '')}>
                            { plataformaSeleccionada !== undefined && 
                            <>
                                <div className="breadcrumb div__breadcrumb-modal-inversiones">
                                <a className="breadcrumb__portada" onClick={()=> volverListadoInversionesPlataforma()}>Plataformas</a>
                                /
                                <a className={equipoSeleccionado === null ? "breadcrumb__activo" : ""} onClick={()=> volverListadoEquipos()}>{plataformaSeleccionada.nombre}</a>
                                {equipoSeleccionado !== null && (
                                    <>
                                    /
                                    <a className="breadcrumb__activo">{equipoSeleccionado.nombre}</a>
                                    </>
                                )}
                                </div>
                            </>
                            }
                            {idPlataformaSeleccionada === null ?
                            
                                <table className="ficha__tabla">
                                    <tbody>
                                        <tr>
                                        <th>Plataforma</th>
                                        <th>Presupuesto</th>
                                        </tr>
                                        { inversiones?.map( (inversion: any, index: number) => {
                                        return (
                                            <tr onClick={() => goToDetallePlataforma(inversion.id)} key={index}> 
                                                <th>{inversion.nombre}</th>
                                                <td><span className="">{inversion.presupuesto !== null ? getNumeroFormateado(inversion.presupuesto)+ "€" : "-" }</span></td>
                                            </tr>
                                        )

                                        })}
                                    
                                    </tbody>
                                </table>
                            :
                                equipoSeleccionado === null  ?
                                    <table className="ficha__tabla">
                                    <tbody>
                                        <tr>
                                        <th>Equipo</th>
                                        <th>Presupuesto</th>
                                        </tr>
                                        { equipos?.map( (equipo: any, index: number) => {
                                            return (
                                                <tr onClick={() => goToDetalleEquipoModal(equipo)}  key={index}> 
                                                <th>{equipo.nombre}</th>
                                                <td><span className="">{equipo.presupuesto !== null ? getNumeroFormateado(equipo.presupuesto)+ "€" : "-"}</span></td>
                                            </tr>
                                        )
                                        
                                    })}
                                    
                                    </tbody>
                                </table>
                                :
                                    infoEquipo !== null && (
                                        <ContenidoModalColoresEquipo equipo={infoEquipo}></ContenidoModalColoresEquipo>                                  
                                    )
                                    
                            }
                        </div>
                    
                    </>
                }
                </div>
            </DimmedAyuda>
            </DimmedAyuda>
            </DimmedAyuda>
        </div>
    )
        
    }


