import { Component } from "react";

interface PropMenuSistemaSubsistema  {
    idSubsistemaSeleccionado: number | null;
    idSistemaDesplegado: number | null;
    sistema: any;
    onChangeSubsistema: (subistema: number) => void;
    onDesplegarSistema: (sistema: number | null) => void;
}

type StateMenuSistemaSubsistema = {
    
}

export class MenuSistemaSubsistema extends Component<PropMenuSistemaSubsistema, StateMenuSistemaSubsistema> {

    onChangeSubsistema = (subsistema: number) => {
        this.props.onChangeSubsistema(subsistema)
    }

    onDesplegarSistema = () => {
        if (this.estaDesplegado()) {
            this.props.onDesplegarSistema(null);
        } else {
            this.props.onDesplegarSistema(this.props.sistema.id)
        }
    }

    estaDesplegado() {
        if (this.props.idSistemaDesplegado && this.props.idSistemaDesplegado == this.props.sistema.id) {
            return true;
        } else {
            return false;
        }
    }

    render () {
        let sistema = this.props.sistema;
        return(
        <div className="sidenav__menu">
            <div className={"sidenav__menu__sistema " + (this.estaDesplegado() ? "sistema-activo" : "")}>
                <div className="sidenav__menu__sistema__titulo" onClick={() => this.onDesplegarSistema()}>
                    {sistema.nombre}
                    <span className="flecha-acordeon"></span>
                </div>
                <div className="sidenav__menu__subsistemas">
                    {sistema.subsistemas.map((subsistema: any) =>
                        <div key={subsistema.id} className={"sidenav__menu__subsistema " + (subsistema.id == this.props.idSubsistemaSeleccionado ? "subsistema-activo " : "") + (subsistema.frio ? "frio " : "")} onClick={() => this.onChangeSubsistema(subsistema.id)}>
                            {subsistema.nombre}
                        </div>
                    )}
                </div>
            </div>
        </div>)
    }
 }
