
export function getFechaFormateadaSinHora(fecha: Date, formato?: string) {
    if (!formato) {
        formato = 'dd/mm/aaaa';
    }    
    if (formato === 'dd-mm-aaaa') {
        // el formato llamado "little endian"
        return getDia(fecha) + '-' + getMes(fecha) + '-' + getAnyo(fecha);
    } else if (formato === 'aaaa-mm-dd') {
        // el formato llamado "big endian"
        return getAnyo(fecha) + '-' + getMes(fecha) + '-' + getDia(fecha);
    } else if (formato === 'dd/mm/aaaa') {
        return getDia(fecha) + '/' + getMes(fecha) + '/' + getAnyo(fecha);
    } else if (formato === 'dd.mm.aaaa') {
        return getDia(fecha) + '.' + getMes(fecha) + '.' + getAnyo(fecha);
    } else if (formato === 'mm/dd/aaaa') {
        return getMes(fecha) + '/' + getDia(fecha) + '/' + getAnyo(fecha);
    } else if (formato === 'aaaa/mm/dd') {
        return getAnyo(fecha) + '/' + getMes(fecha) + '/' + getDia(fecha);
    } else if (formato === 'aaaammdd') {
        return getAnyo(fecha) + "" + getMes(fecha) + "" + getDia(fecha);
    }
}

export function getAnyo(fecha: Date) {
    return fecha.getFullYear();
}

export function getMes(fecha: Date) {
    var temp = fecha.getMonth() + 1;
    return temp < 10 ? '0' + temp : temp;
}

export function getDia(fecha: Date) {
    var temp = fecha.getDate();
    return temp < 10 ? '0' + temp : temp;
}

export function getFechaFormatoComprimido(fecha: Date) {
    return getAnyo(fecha) + "" + getMes(fecha) + "" + getDia(fecha)  
}
