import _ from 'lodash';
import React, { useEffect, useState } from 'react';

interface RoleContextInterface {
  groups: string[];
  setGroups: (groups: string[]) => void;
}

export const RoleContext = React.createContext<RoleContextInterface>({

    groups: [],
    setGroups: (groups: string[]) => {}
}
);

type PropsRoleContextProvider = {

}

export const RoleContextProvider: React.FC<PropsRoleContextProvider> = (props ) => {

    const setGroups = (groups: string[]) => {
      setState({...state, groups: groups})
    }
  
    const initState = {
        groups: [],
        setGroups: setGroups
    } 
  
    const [state, setState] = useState<RoleContextInterface>(initState)
  
    useEffect( () => {
      let groupsJson = sessionStorage.getItem('groups');
      if(groupsJson){
        state.setGroups(JSON.parse(groupsJson))
      }
    },[]);

    useEffect( () => {
      sessionStorage.setItem('groups', JSON.stringify(state.groups));
    },[state.groups]);

    return (
      <RoleContext.Provider value={state}>
        {props.children}
      </RoleContext.Provider>
    )
  }
export default RoleContextProvider;

export function isRoleUsuarioEroski(userGroups: string[]){
  //  let isRole = _.includes(userGroups, 'InfoBox');
  let isRole = _.includes(userGroups, 'Usuario-Eroski');
  return isRole;

}
export function isRoleAdminEroski(userGroups: string[]){
  // let isRole = _.includes(userGroups, 'InfoBox');
  let isRole = !isRoleUsuarioEroski(userGroups) && ( _.includes(userGroups, 'Admin-Eroski') 
          || _.includes(userGroups, 'InfoBox')
          || _.includes(userGroups, 'Versia')
          || _.includes(userGroups, 'SwaggerViewer'));
  return isRole;

}
