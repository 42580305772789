import React, { useEffect } from 'react';
import './App.css';
import { Redirect, Route } from 'react-router';
import { BrowserRouter as Router, useHistory, useLocation } from 'react-router-dom';
import Login from './pantallas/Login';
import { Portada } from './pantallas/Portada';
import { Modelo } from './pantallas/Modelo';
import { Plataformas } from './pantallas/Plataformas';
import axios, { AxiosError } from 'axios';
import { BuscadorAvanzado } from './pantallas/BuscadorAvanzado';
import { Comparador } from './pantallas/Comparador';
import RoleContextProvider from './context/RoleContext';
import BotonAyuda from './componentes/BotonAyuda';
import OnBoardingProvider from './context/OnBoardingContext';
import { URL_API } from './service/utils/http';
import { AuthProvider } from './context/authContext';
import  useAuth  from "./context/authContext";
import WithAxios from './service/utils/WithAxios';


//parte con login

export const ProtectedLayout = () => {



  const location = useLocation();
  const {authed, loginSiSesionAnterior, logout} = useAuth();

  useEffect( () => {
    if(authed === false){
      loginSiSesionAnterior();
    }
  },[])

  //intercepto error 401
  axios.interceptors.response.use((response) => {
    return response;
    },(error: AxiosError ) => {
      if (error?.response?.status === 401) {
          logout();
      } 
      return Promise.reject(error);
  })

 

  if (authed === true) {

    axios.defaults.headers.Authorization = 'Token ' + window.sessionStorage.getItem('token');

    return(
      <React.Fragment>
        <Route exact path="/portada" component={Portada} />  
        <Route exact path="/portada/modelo" component={Modelo} />  
        <Route exact path="/portada/modelo/:id" component={Modelo} /> 
        <Route exact path="/portada/plataformas" component={Plataformas} />  
        <Route exact path="/portada/buscador-avanzado" component={BuscadorAvanzado} /> 
        <Route exact path="/portada/comparador" component={Comparador} /> 
         {/* ayuda usuario */}
         {/* para portada se pinta en Portada.tsx para q pueda hacerse onboarding (ayuda) */}
         {location.pathname !== "/portada" &&
          <BotonAyuda></BotonAyuda> 
        }
      </React.Fragment>
    );
  } else {
    return(
      <Redirect to="/" />
    )
  }
}

function App() {
  axios.defaults.baseURL = URL_API;
  return (
    
    <AuthProvider>
      <WithAxios>
        <RoleContextProvider>
          <OnBoardingProvider>
              <Router >
                <Route exact path="/" component={Login} />  
                <Route path="/portada" component={ProtectedLayout} /> 
              </Router>
          </OnBoardingProvider>
        </RoleContextProvider>
      </WithAxios>
    </AuthProvider>
    
  );
}


export default App;
