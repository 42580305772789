import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { onBoardingPasosDetallePlataforma } from "../constantes/onBoarding";
import fotoPlataforma from '../img/plataformas-placeholder.jpg';
import { descargarEquiposCSV } from "../service/DescargarArchivo";
import { getNumeroFormateado } from "../utils/FuncionesNumeros";
import { BotonColorFicha } from "./BotonColor";
import DimmedAyuda from "./DimmedAyuda";
import { ModalColoresPlataforma } from "./ModalColoresPlataforma";


interface PropFichaPlataforma extends RouteComponentProps {  
    plataforma: any;  
    onClickPlataforma?: (idPlataforma: number | null) => void;
    pasoAyuda: number | null;
    callbackActualizarPasoAyuda: (pasoAyuda: number | null) => void;
}

type StateFichaPlataforma = {
    mostrarModalColoresPlataforma: boolean;
    tipoAlertaColoresPlataforma: string;
}

export class FichaPlataforma extends Component<PropFichaPlataforma, StateFichaPlataforma> {
    state = {
        mostrarModalColoresPlataforma: false,
        tipoAlertaColoresPlataforma: ""
    }
    
    onClickPlataforma = (idPlataforma: number) => {
        if (this.props.onClickPlataforma) {
            this.props.onClickPlataforma(idPlataforma)
        }        
    }
    getDescripcionTipoPlataforma(tipo: any) {
        if (tipo) {
            if (tipo.nombre == "Seco") {
                return "Plataforma de seco";
            } else if (tipo.nombre == "Fresco") {
                return "Plataforma de fresco";
            }
        } else return "-";
    }

    getDireccion(plataforma: any) {
        let filas = [];
        if (plataforma.direccion_1 || plataforma.direccion_2 || plataforma.poblacion || plataforma.provincia || plataforma.cod_post) {
            if (plataforma.direccion_1) {
                filas.push(plataforma.direccion_1);
            }
            if (plataforma.direccion_2) {
                filas.push(<><br></br>{plataforma.direccion_2}</>);
            }
            if (plataforma.poblacion) {
                filas.push(<><br></br>{plataforma.poblacion}</>);
            }
            if (plataforma.provincia) {
                filas.push(<><br></br>{plataforma.provincia.nombre}</>);
            }
            if (plataforma.cod_post) {
                filas.push(<><br></br>{plataforma.cod_post}</>);
            }
            return filas;
        } else return "-"
    }

    getContacto(plataforma: any) {
        let filas = [];
        if (plataforma.contacto_nombre || plataforma.contacto_apellidos || plataforma.contacto_mail || plataforma.contacto_movil || plataforma.contacto_fijo) {
            if (plataforma.contacto_nombre) {
                filas.push(plataforma.contacto_nombre);
            }
            if (plataforma.contacto_apellidos) {
                filas.push(" " + plataforma.contacto_apellidos);
            }
            if (plataforma.contacto_mail) {
                filas.push(<><br></br>{plataforma.contacto_mail}</>);
            }
            if (plataforma.contacto_movil) {
                filas.push(<><br></br>{plataforma.contacto_movil}</>);
            }
            if (plataforma.contacto_fijo) {
                filas.push(<><br></br>{plataforma.contacto_fijo}</>);
            }
            return filas;
        } else return "-"
    }

    abrirModalColoresPlataforma = (tipo: string) => {
        this.setState({
            mostrarModalColoresPlataforma: true,
            tipoAlertaColoresPlataforma: tipo
        })
      }
    
      cerrarModalColoresPlataforma = () => {
        this.setState({
            mostrarModalColoresPlataforma: false,
            tipoAlertaColoresPlataforma: ""
        })
      }

      descargarCSV = () => {
        descargarEquiposCSV(this.props.plataforma.id)
      }

    render () {
        let plataforma = this.props.plataforma;
        const { user, setUser } = this.context;

        return(
            <>
            <div className="ficha">
                <img className="ficha__portada" src={plataforma.foto ? plataforma.foto : fotoPlataforma}></img>
                <div className="ficha__titulo ficha__titulo_descargar">
                    <h1>{plataforma.nombre}</h1>
                    <DimmedAyuda paso={3} pasoActual={this.props.pasoAyuda} orientation_mobile="bottom" callbackActulizarPasoActual={this.props.callbackActualizarPasoAyuda} objetos={onBoardingPasosDetallePlataforma} orientation="left">
                        <button onClick={this.descargarCSV}>Descargar equipos CSV</button>
                    </DimmedAyuda>
                </div>
                <div className="ficha__contenido">
                   

                    <DimmedAyuda paso={1} pasoActual={this.props.pasoAyuda} orientation_mobile="bottom" orientation_tablet="bottom" callbackActulizarPasoActual={this.props.callbackActualizarPasoAyuda} objetos={onBoardingPasosDetallePlataforma}>
                        <table id="ficha__tabla__inversiones-futuras" className="ficha__tabla">
                            <caption>Inversiones futuras</caption>
                            <tbody>
                                <tr>
                                    <th>Vida útil</th>
                                    <td><BotonColorFicha onClick={plataforma.colores.vida_util.color !== "verde" ? () => this.abrirModalColoresPlataforma("vida_util") : undefined} color={plataforma.colores.vida_util.color}></BotonColorFicha></td>
                                </tr>
                                <tr>
                                    <th>Eficiencia energética</th>
                                    <td><BotonColorFicha  onClick={plataforma.colores.eficiencia_energetica.color !== "verde" ? () => this.abrirModalColoresPlataforma("eficiencia_energetica") : undefined} color={plataforma.colores.eficiencia_energetica.color}></BotonColorFicha></td>
                                    {/* <td><span onClick={(event: any) => {plataforma.colores.eficiencia_energetica.color != "verde" && this.abrirModalColoresPlataforma("eficiencia_energetica")}} className={`ficha__semaforo ${plataforma.colores.eficiencia_energetica.color}`}></span></td> */}
                                </tr>
                                <tr>
                                    <th>Normativa</th>
                                    <td><BotonColorFicha onClick={plataforma.colores.normativa.color !== "verde" ? () =>  this.abrirModalColoresPlataforma("normativa"): undefined} color={plataforma.colores.normativa.color}></BotonColorFicha></td>
                                    {/* <td><span onClick={(event: any) => {plataforma.colores.normativa.color != "verde" && this.abrirModalColoresPlataforma("normativa")}} className={`ficha__semaforo ${plataforma.colores.normativa.color}`}></span></td> */}
                                </tr>
                                <tr>
                                    <th>Imprevisto</th>
                                    <td><BotonColorFicha onClick={ plataforma.colores.imprevisto.color !== "verde" ? () => this.abrirModalColoresPlataforma("imprevisto") : undefined} color={plataforma.colores.imprevisto.color}></BotonColorFicha></td>
                                    {/* <td><span onClick={(event: any) => {plataforma.colores.imprevisto.color != "verde" && this.abrirModalColoresPlataforma("imprevisto")}} className={`ficha__semaforo ${plataforma.colores.imprevisto.color}`}></span></td> */}
                                </tr>
                                {plataforma.presupuesto && (
                                <tr>
                                    <th>Presupuesto</th>
                                    <td>{getNumeroFormateado(plataforma.presupuesto)}€</td>
                                </tr> 
                                )}
                            </tbody>
                        </table>
                    </DimmedAyuda>
                    <DimmedAyuda paso={2} pasoActual={this.props.pasoAyuda} orientation_mobile="top" callbackActulizarPasoActual={this.props.callbackActualizarPasoAyuda} objetos={onBoardingPasosDetallePlataforma} orientation="left">
                        <table  id="ficha__tabla__informacion-general" className="ficha__tabla">
                                <caption>Información general</caption>
                                <tbody>
                                    <tr>
                                    <th>CIF</th>
                                    <td>{plataforma.CIF ? plataforma.CIF : "-"}</td>
                                    </tr>
                                    <tr>
                                    <th>Tipo</th>
                                    <td>{this.getDescripcionTipoPlataforma(plataforma.tipo)}</td>
                                    </tr>
                                    <tr>
                                    <th>Dirección</th>
                                    <td>
                                    {this.getDireccion(plataforma)}
                                    </td>
                                    </tr>
                                    <tr>
                                    <th>Contacto</th>
                                    <td>
                                        {this.getContacto(plataforma)}
                                    </td>
                                    </tr>
                                    <tr>
                                    <th>Fecha de inauguración</th>
                                    <td>{plataforma.fec_inauguracion ? plataforma.fec_inauguracion : "-"}</td>
                                    </tr>
                                    <tr>
                                    <th>Fecha reformas</th>
                                    <td>{plataforma.fec_reforma ? plataforma.fec_reforma : "-"}</td>
                                    </tr>                        
                                    <tr>
                                    <th>M2 construídos</th>
                                    <td>{plataforma.m2 ? plataforma.m2 + " m2" : "-"}</td>
                                    </tr>
                                    <tr>
                                    <th>M3 refrigerados</th>
                                    <td>{plataforma.m3_refri ? plataforma.m3_refri + " m3" : "-"}</td>
                                    </tr>
                                </tbody>
                        </table>
                    </DimmedAyuda>
                </div>
          </div>    
            {this.state.mostrarModalColoresPlataforma && (
                <ModalColoresPlataforma {...this.props} plataforma={this.props.plataforma} tipo={this.state.tipoAlertaColoresPlataforma}  onCerrarModal={this.cerrarModalColoresPlataforma}/>
            )}
        </>
          )
    }
 }


