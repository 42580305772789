import axios from "axios";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import _ from 'lodash';
import { Buscador } from "../componentes/Buscador";
import PageComparadorDimmed from "../componentes/PageComparadorDimmed";
import DimmedAyuda from "../componentes/DimmedAyuda";
import { onBoardingPasosFiltros } from "../constantes/onBoarding";

interface RouteParams {
  id: string
}

interface PropComparador extends RouteComponentProps<RouteParams>{

}

type StateComparador = {
  isMobile: boolean;
  lateralVisible: boolean;
  equipos: any[] | [];
  filtros: any;

  plataformasSeleccionadas: number[] | [];
  equiposSeleccionadas: string[] | [];

  pasoAyuda?: number | null;

}

export class Comparador extends Component<PropComparador, StateComparador> { 

    tarjeta: string | null = null;
    nombreTarjeta: string  = "";

    constructor(props: PropComparador) {
      super(props);
      let filtrosBusqueda = this.props.location.search;
      let equipos: string[] | [] = [];
      let plataformas: number[] | [] = [];
      if (filtrosBusqueda !== "") {
        this.tarjeta = this.getParametroURLQuery("tarjeta") as string;
        this.nombreTarjeta = this.getParametroURLQuery("nombre") ? decodeURI(this.getParametroURLQuery("nombre") as string): "";
        if (this.getParametroURLQuery("equipos")) {
          equipos = JSON.parse(decodeURI(this.getParametroURLQuery("equipos") as string));
        }
        if (this.getParametroURLQuery("plataformas")) {
          plataformas = JSON.parse(decodeURI(this.getParametroURLQuery("plataformas") as string));
        }
      }
      this.state = {
        isMobile: window.innerWidth < 860,
        lateralVisible: window.innerWidth < 860 ? false : true,
        equipos: [],
        filtros: {} as any,
        plataformasSeleccionadas: plataformas ? plataformas: [],
        equiposSeleccionadas: equipos ? equipos : []
      }
    }

    setPasoAyuda = (pasoAyuda: number | null) => {
      this.setState({pasoAyuda: pasoAyuda})
    }

    handleWindowResize = () => {
      this.setState(
        { isMobile: window.innerWidth < 860,
          lateralVisible: window.innerWidth < 860 ? false : true
        }
      );
    }


    componentDidMount() {     
      this.buscar();
      window.addEventListener('resize', this.handleWindowResize);
    }

    componentDidUpdate(prevProps: PropComparador, prevState: StateComparador) {
      if (prevProps.location.pathname != this.props.location.pathname || prevProps.location.search != this.props.location.search) {
        let filtrosBusqueda = this.props.location.search;
        let equipos: string[] | [] = [];
        let plataformas: number[] | [] = [];
        if (filtrosBusqueda !== "") {
          let tarjetaAnterior = _.clone(this.tarjeta);
          this.tarjeta = this.getParametroURLQuery("tarjeta") as string;
          this.nombreTarjeta = this.getParametroURLQuery("nombre") ? decodeURI(this.getParametroURLQuery("nombre") as string): "";
          if (this.getParametroURLQuery("equipos")) {
            equipos = JSON.parse(decodeURI(this.getParametroURLQuery("equipos") as string));
          }
          if (this.getParametroURLQuery("plataformas")) {
            plataformas = JSON.parse(decodeURI(this.getParametroURLQuery("plataformas") as string));
          }
          if (tarjetaAnterior != this.tarjeta 
            || !_.isEqual(equipos, prevState.equiposSeleccionadas)
            || !_.isEqual(plataformas, prevState.plataformasSeleccionadas)
          ) { 
            this.setState({
              filtros: {} as any,
              plataformasSeleccionadas: plataformas ? plataformas: [],
              equiposSeleccionadas: equipos ? equipos : []
            }, () => this.buscar())
          }          
        }
      }
    }

    getParametroURLQuery = (variable: string) => {
      var query = this.props.location.search.substring(1);
      var vars = query.split("&");
      for (var i=0;i<vars.length;i++) {
              var pair = vars[i].split("=");                   
              if(pair[0] == variable){return pair[1];}
      }
      return(false);
    }

    buscar() {
      let instancia = this;
        const getPostData = () => {
          return { 
            "id": Number(this.tarjeta),
            "plataformas": this.state.plataformasSeleccionadas,
            "equipos": this.state.equiposSeleccionadas
          }         
        }
        axios.all([axios.post('/comparador-tarjeta-tool/', getPostData()),
        axios.get('/comparador-available-filters/?tarjeta=' + this.tarjeta)])
        .then(axios.spread(function (equipos, filtros) {
          let equiposBuscados = equipos.data;
          let filtrosBuscados = filtros.data;
          instancia.setState({
              equipos: equiposBuscados,
              filtros: filtrosBuscados
          }, () => instancia.cambiarURL())          
        }));     
    }

    hayFiltrosSeleccionados () {
      return (this.state.plataformasSeleccionadas.length > 0 || this.state.equiposSeleccionadas.length > 0)
    }

    cambiarURL = () => {
      this.props.history.replace("/portada/comparador?tarjeta=" + this.tarjeta + "&nombre=" + encodeURI(this.nombreTarjeta)
      + (this.state.equiposSeleccionadas.length > 0 ? "&equipos=" + encodeURI(JSON.stringify(this.state.equiposSeleccionadas))  : "")
      + (this.state.plataformasSeleccionadas.length > 0 ? "&plataformas=" + encodeURI(JSON.stringify(this.state.plataformasSeleccionadas)) : "")
      )
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowResize);
    }


    onVolver = () => {
      this.props.history.goBack();
    }

    onVolverPortada = () => {
      this.props.history.push("/portada")
    }

    onVolverModelo = () => {
      this.props.history.push("/portada/modelo/2?sistema=1")
    }


    toggleLateral = () => {
      this.setState({
        lateralVisible: !this.state.lateralVisible
      })
    }

    onChangeFiltroPlataforma = (idPlataforma: number) => {
      var plataformasSeleccionadas: number[] = _.cloneDeep(this.state.plataformasSeleccionadas);
      if (_.indexOf(plataformasSeleccionadas, idPlataforma) !== -1) {
        _.pull(plataformasSeleccionadas, idPlataforma);
      } else {
        plataformasSeleccionadas.push(idPlataforma)
      }
      this.setState({
        plataformasSeleccionadas: plataformasSeleccionadas,
        lateralVisible: false
      }, () => this.buscar())
    }

    onChangeFiltroEquipo = (nombreEquipo: string) => {
      var equiposSeleccionadas: string[] = _.cloneDeep(this.state.equiposSeleccionadas);
      if (_.indexOf(equiposSeleccionadas, nombreEquipo) !== -1) {
        _.pull(equiposSeleccionadas, nombreEquipo);
      } else {
        equiposSeleccionadas.push(nombreEquipo)
      }
      this.setState({
        equiposSeleccionadas: equiposSeleccionadas,
        lateralVisible: false
      }, () => this.buscar())
    }

    onDocumentacion(plataforma: any, sistema: any, subsistema: any, equipo: any) {
      this.props.history.push("/portada/modelo/" + plataforma + (sistema ? "?sistema=" + sistema : "") + (subsistema ? "&subsistema=" + subsistema : "")  + (equipo ? "&equipo=" + equipo : ""), true)
    }

    render() {
      let pasoAyuda: number | null = this.state.pasoAyuda=== undefined ? null : this.state.pasoAyuda! as number | null;

        return (
          <PageComparadorDimmed pasoActual={this.state.pasoAyuda} callbackIniciarPasoAyuda={this.setPasoAyuda}>

            <div className="libro">
              <div className="topnav">
                <div className="topnav__nav"> 
                  <button className="btn-volver" onClick={this.onVolver}></button>
                  <div className="breadcrumb">
                    <a className="breadcrumb__portada" onClick={this.onVolverPortada}>Portada</a>
                    /
                    <a onClick={this.onVolverModelo}>Modelo</a>
                    /
                    <a className="breadcrumb__activo">Comparador</a>
                  </div>
                </div>
                <div className="topnav__buscador">
                  <Buscador {...this.props}/>
                </div>
                <div className="topnav-logo-eroski"></div>
              </div>

              <div className="sidenav" style={{display: !this.state.isMobile || this.state.lateralVisible ? 'block' : 'none'}}>
                <DimmedAyuda paso={0} pasoActual={! this.state.isMobile ? pasoAyuda : null} callbackActulizarPasoActual={this.setPasoAyuda} objetos={onBoardingPasosFiltros} >

                  <button className="btn-cerrar" onClick={this.toggleLateral}></button>
                  <h4>Filtrar resultados</h4>

                  {this.state.filtros && this.state.filtros.plataformas && (
                    <div style={{marginTop: '1rem'}}>
                    <label>Plataformas</label>
                    {this.state.filtros.plataformas.map((plataforma: any) =>
                      plataforma.nombre && <div><input type="checkbox" checked={_.indexOf(this.state.plataformasSeleccionadas, plataforma.id) !== -1} onChange={() => this.onChangeFiltroPlataforma(plataforma.id)}/> {plataforma.nombre}</div>
                    )}
                    </div>
                  )}

                  {this.state.filtros && this.state.filtros.equipos && (
                    <div style={{marginTop: '1rem'}}>
                    <label>Equipos</label>
                    {this.state.filtros.equipos.map((equipo: any) =>
                      equipo.nombre && <div className="filtro__equipo"><input type="checkbox" checked={_.indexOf(this.state.equiposSeleccionadas, equipo.nombre) !== -1} onChange={() => this.onChangeFiltroEquipo(equipo.nombre)}/> {equipo.nombre}</div>
                    )}
                    </div>
                  )}
                  </DimmedAyuda>
              </div>

              <div className="main main__buscador" style={{display: !this.state.isMobile || !this.state.lateralVisible ? 'block' : 'none'}}>
                <div className="container_btn-menu">
                  <DimmedAyuda paso={0} pasoActual={this.state.isMobile ? pasoAyuda : null} orientation_mobile="bottom" callbackActulizarPasoActual={this.setPasoAyuda} objetos={onBoardingPasosFiltros} >
                    <button className="btn-menu" onClick={this.toggleLateral}></button>
                  </DimmedAyuda>
                </div>
                  <h2>Comparando "{this.nombreTarjeta}"</h2>    
                  <div className="main__tarjetas">
                  {this.state.equipos.length > 0 && this.state.equipos.map((equipo:any, index:number) =>  
                  
                          <DimmedAyuda  paso={1} pasoActual={index === 0 ? pasoAyuda : null} orientation_mobile="top" orientation_tablet="bottom" callbackActulizarPasoActual={this.setPasoAyuda} objetos={onBoardingPasosFiltros} >
                            <div className="main__tarjeta" key={index}>
                              <div className="main__buscador__tarjeta">
                                <p className="main__buscador__tarjeta__ruta">
                                  {equipo.plataforma_desc} / {equipo.sistema_desc} / {equipo.subsistema_desc}
                                  <br></br>
                                  <span className="txt-rojo">{equipo.tarjeta_desc}</span>
                                </p>
                                <p className="main__buscador__tarjeta__nombre">{equipo.nombre}</p>
                                <div className="main__buscador__tarjeta__footer">
                                  <div className="tipo equipo"></div>
                                  <div className="botones">
                                    <button onClick={() => this.onDocumentacion(equipo.plataforma, equipo.sistema, equipo.subsistema, equipo.id)}>Ver</button>
                                  </div> 
                                </div>
                              </div> 
                            </div>
                          </DimmedAyuda>
                      )}
                  </div>      
              </div> {/* fin .main */}
            </div>
            </PageComparadorDimmed>

          );
    }

   
}