import axios, { AxiosResponse } from "axios";
import _ from "lodash";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { getFechaFormateadaSinHora } from "../utils/FuncionesFechas";
import { BotonColorFicha } from "./BotonColor";

interface PropsColoresPlataforma extends RouteComponentProps {   
    plataforma: any;
    tipo: string;
    onCerrarModal: () => void;
}

type StateColoresPlataforma = {
    equipos: any[] | [];
}

export class ModalColoresPlataforma extends Component<PropsColoresPlataforma, StateColoresPlataforma> {


    state={
        equipos: []
    }

    componentDidMount() {      
      this.recuperarColoresPlataforma();
    }

    onCerrarModal = () => {
      this.props.onCerrarModal()
    }

    recuperarColoresPlataforma() {
      axios.get('/lista-equipos-colores/?plataforma=' + this.props.plataforma.id + "&alerta=" + this.props.tipo).then((response: AxiosResponse) => {
        this.setState({
          equipos: response.data,
        })
      })
    }

    onAbrirEquipo(equipo: any) {
      this.props.history.push("/portada/modelo/" + this.props.plataforma.id + (equipo.latest_equipo.sistema.id ? "?sistema=" + equipo.latest_equipo.sistema.id : "") + (equipo.latest_equipo.subsistema.id ? "&subsistema=" + equipo.latest_equipo.subsistema.id  : "") + (equipo.id ? "&equipo=" + equipo.id  : ""), true)
    }

    render () {
        let plataforma = this.props.plataforma;

        return(
          <div className="modal-historico" style={{display: 'block'}}>
            <div className="historial__equipo">
                    <div className={"historial__equipo__titulo"}>
                      <span>Inversiones futuras: {plataforma.nombre}</span>
                      <div className="cerrar" onClick={this.onCerrarModal}></div>
                    </div>
                    <div className="historial__equipo__inversiones" key={plataforma.id}>
                    <table className="ficha__tabla">
                        <tbody>
                        {this.state.equipos && this.state.equipos.map((equipo: any) => 
                          equipo.latest_equipo.nombre && (<tr style={{cursor: 'pointer'}} onClick={() => this.onAbrirEquipo(equipo)}>
                              <th>{equipo.latest_equipo.nombre}</th>
                              <td style={{textAlign: 'right'}}><BotonColorFicha color={equipo.latest_equipo[this.props.tipo].color} onClick={() => this.onAbrirEquipo(equipo)}></BotonColorFicha></td>
                          </tr>)            
                        )}
                        </tbody>
                    </table>
                                         
                    </div>                   
            </div>
            </div>
        )
    }
 }
